/**
 * Generated by @openapi-codegen
 *
 * @version 1.0
 */
import * as reactQuery from "@tanstack/react-query";
import { useFormsContext, FormsContext } from "./formsContext";
import type * as Fetcher from "./formsFetcher";
import { formsFetch } from "./formsFetcher";
import type * as Responses from "./formsResponses";

export type GetReportPathParams = {
  reportId: string;
};

export type GetReportError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetReportVariables = {
  pathParams: GetReportPathParams;
} & FormsContext["fetcherOptions"];

export const fetchGetReport = (variables: GetReportVariables, signal?: AbortSignal) =>
  formsFetch<Responses.Report, GetReportError, undefined, {}, {}, GetReportPathParams>({
    url: "/v1/reports/{reportId}",
    method: "get",
    ...variables,
    signal
  });

export const useGetReport = <TData = Responses.Report>(
  variables: GetReportVariables,
  options?: Omit<reactQuery.UseQueryOptions<Responses.Report, GetReportError, TData>, "queryKey" | "queryFn">
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<Responses.Report, GetReportError, TData>(
    queryKeyFn({ path: "/v1/reports/{reportId}", operationId: "getReport", variables }),
    ({ signal }) => fetchGetReport({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PatchReportPathParams = {
  reportId: string;
};

export type PatchReportError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type PatchReportVariables = {
  pathParams: PatchReportPathParams;
} & FormsContext["fetcherOptions"];

export const fetchPatchReport = (variables: PatchReportVariables, signal?: AbortSignal) =>
  formsFetch<Responses.Report, PatchReportError, undefined, {}, {}, PatchReportPathParams>({
    url: "/v1/reports/{reportId}",
    method: "patch",
    ...variables,
    signal
  });

export const usePatchReport = (
  options?: Omit<reactQuery.UseMutationOptions<Responses.Report, PatchReportError, PatchReportVariables>, "mutationFn">
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<Responses.Report, PatchReportError, PatchReportVariables>(
    (variables: PatchReportVariables) => fetchPatchReport({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DeleteReportPathParams = {
  reportId: string;
};

export type DeleteReportError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type DeleteReportVariables = {
  pathParams: DeleteReportPathParams;
} & FormsContext["fetcherOptions"];

export const fetchDeleteReport = (variables: DeleteReportVariables, signal?: AbortSignal) =>
  formsFetch<undefined, DeleteReportError, undefined, {}, {}, DeleteReportPathParams>({
    url: "/v1/reports/{reportId}",
    method: "delete",
    ...variables,
    signal
  });

export const useDeleteReport = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, DeleteReportError, DeleteReportVariables>, "mutationFn">
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<undefined, DeleteReportError, DeleteReportVariables>(
    (variables: DeleteReportVariables) => fetchDeleteReport({ ...fetcherOptions, ...variables }),
    options
  );
};

export type PutReportPathParams = {
  reportId: string;
};

export type PutReportError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type PutReportVariables = {
  pathParams: PutReportPathParams;
} & FormsContext["fetcherOptions"];

export const fetchPutReport = (variables: PutReportVariables, signal?: AbortSignal) =>
  formsFetch<Responses.Report, PutReportError, undefined, {}, {}, PutReportPathParams>({
    url: "/v1/reports/{reportId}",
    method: "put",
    ...variables,
    signal
  });

export const usePutReport = (
  options?: Omit<reactQuery.UseMutationOptions<Responses.Report, PutReportError, PutReportVariables>, "mutationFn">
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<Responses.Report, PutReportError, PutReportVariables>(
    (variables: PutReportVariables) => fetchPutReport({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetAllReportsError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type GetAllReportsVariables = FormsContext["fetcherOptions"];

export const fetchGetAllReports = (variables: GetAllReportsVariables, signal?: AbortSignal) =>
  formsFetch<Responses.Reports, GetAllReportsError, undefined, {}, {}, {}>({
    url: "/v1/reports",
    method: "get",
    ...variables,
    signal
  });

export const useGetAllReports = <TData = Responses.Reports>(
  variables: GetAllReportsVariables,
  options?: Omit<reactQuery.UseQueryOptions<Responses.Reports, GetAllReportsError, TData>, "queryKey" | "queryFn">
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<Responses.Reports, GetAllReportsError, TData>(
    queryKeyFn({ path: "/v1/reports", operationId: "getAllReports", variables }),
    ({ signal }) => fetchGetAllReports({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PostReportError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.Error;
    }
  | {
      status: 401;
      payload: Responses.Error;
    }
>;

export type PostReportVariables = FormsContext["fetcherOptions"];

export const fetchPostReport = (variables: PostReportVariables, signal?: AbortSignal) =>
  formsFetch<Responses.Report, PostReportError, undefined, {}, {}, {}>({
    url: "/v1/reports",
    method: "post",
    ...variables,
    signal
  });

export const usePostReport = (
  options?: Omit<reactQuery.UseMutationOptions<Responses.Report, PostReportError, PostReportVariables>, "mutationFn">
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<Responses.Report, PostReportError, PostReportVariables>(
    (variables: PostReportVariables) => fetchPostReport({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetAllAnswersForUserError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type GetAllAnswersForUserVariables = FormsContext["fetcherOptions"];

/**
 * Returns all answers created by a user, and created by any member of a team they manage for all reports
 */
export const fetchGetAllAnswersForUser = (variables: GetAllAnswersForUserVariables, signal?: AbortSignal) =>
  formsFetch<Responses.Answers, GetAllAnswersForUserError, undefined, {}, {}, {}>({
    url: "/v3/reports/getAllAnswersForUser",
    method: "get",
    ...variables,
    signal
  });

/**
 * Returns all answers created by a user, and created by any member of a team they manage for all reports
 */
export const useGetAllAnswersForUser = <TData = Responses.Answers>(
  variables: GetAllAnswersForUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.Answers, GetAllAnswersForUserError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<Responses.Answers, GetAllAnswersForUserError, TData>(
    queryKeyFn({ path: "/v3/reports/getAllAnswersForUser", operationId: "getAllAnswersForUser", variables }),
    ({ signal }) => fetchGetAllAnswersForUser({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DeleteV3ReportsDeleteAllAnswersForUserError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type DeleteV3ReportsDeleteAllAnswersForUserVariables = FormsContext["fetcherOptions"];

/**
 * Deletes all answers a user has created for all templates
 */
export const fetchDeleteV3ReportsDeleteAllAnswersForUser = (
  variables: DeleteV3ReportsDeleteAllAnswersForUserVariables,
  signal?: AbortSignal
) =>
  formsFetch<undefined, DeleteV3ReportsDeleteAllAnswersForUserError, undefined, {}, {}, {}>({
    url: "/v3/reports/deleteAllAnswersForUser",
    method: "delete",
    ...variables,
    signal
  });

/**
 * Deletes all answers a user has created for all templates
 */
export const useDeleteV3ReportsDeleteAllAnswersForUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteV3ReportsDeleteAllAnswersForUserError,
      DeleteV3ReportsDeleteAllAnswersForUserVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<
    undefined,
    DeleteV3ReportsDeleteAllAnswersForUserError,
    DeleteV3ReportsDeleteAllAnswersForUserVariables
  >(
    (variables: DeleteV3ReportsDeleteAllAnswersForUserVariables) =>
      fetchDeleteV3ReportsDeleteAllAnswersForUser({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetReportCsvPathParams = {
  reportId: string;
};

export type GetReportCsvError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetReportCsvVariables = {
  pathParams: GetReportCsvPathParams;
} & FormsContext["fetcherOptions"];

/**
 * Download the given report as a CSV file
 */
export const fetchGetReportCsv = (variables: GetReportCsvVariables, signal?: AbortSignal) =>
  formsFetch<undefined, GetReportCsvError, undefined, {}, {}, GetReportCsvPathParams>({
    url: "/v1/reports/{reportId}/download-answers",
    method: "get",
    ...variables,
    signal
  });

/**
 * Download the given report as a CSV file
 */
export const useGetReportCsv = <TData = undefined>(
  variables: GetReportCsvVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, GetReportCsvError, TData>, "queryKey" | "queryFn">
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<undefined, GetReportCsvError, TData>(
    queryKeyFn({ path: "/v1/reports/{reportId}/download-answers", operationId: "getReportCsv", variables }),
    ({ signal }) => fetchGetReportCsv({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetAnswersForReportPathParams = {
  reportId: string;
};

export type GetAnswersForReportError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetAnswersForReportVariables = {
  pathParams: GetAnswersForReportPathParams;
} & FormsContext["fetcherOptions"];

export const fetchGetAnswersForReport = (variables: GetAnswersForReportVariables, signal?: AbortSignal) =>
  formsFetch<Responses.Answers, GetAnswersForReportError, undefined, {}, {}, GetAnswersForReportPathParams>({
    url: "/v1/reports/{reportId}/answers",
    method: "get",
    ...variables,
    signal
  });

export const useGetAnswersForReport = <TData = Responses.Answers>(
  variables: GetAnswersForReportVariables,
  options?: Omit<reactQuery.UseQueryOptions<Responses.Answers, GetAnswersForReportError, TData>, "queryKey" | "queryFn">
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<Responses.Answers, GetAnswersForReportError, TData>(
    queryKeyFn({ path: "/v1/reports/{reportId}/answers", operationId: "getAnswersForReport", variables }),
    ({ signal }) => fetchGetAnswersForReport({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PostAnswersForReportPathParams = {
  reportId: string;
};

export type PostAnswersForReportError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.Error;
    }
  | {
      status: 401;
      payload: Responses.Error;
    }
>;

export type PostAnswersForReportVariables = {
  pathParams: PostAnswersForReportPathParams;
} & FormsContext["fetcherOptions"];

export const fetchPostAnswersForReport = (variables: PostAnswersForReportVariables, signal?: AbortSignal) =>
  formsFetch<Responses.Answers, PostAnswersForReportError, undefined, {}, {}, PostAnswersForReportPathParams>({
    url: "/v1/reports/{reportId}/answers",
    method: "post",
    ...variables,
    signal
  });

export const usePostAnswersForReport = (
  options?: Omit<
    reactQuery.UseMutationOptions<Responses.Answers, PostAnswersForReportError, PostAnswersForReportVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<Responses.Answers, PostAnswersForReportError, PostAnswersForReportVariables>(
    (variables: PostAnswersForReportVariables) => fetchPostAnswersForReport({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetAnswersForReportV3PathParams = {
  reportId: string;
};

export type GetAnswersForReportV3Error = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetAnswersForReportV3Variables = {
  pathParams: GetAnswersForReportV3PathParams;
} & FormsContext["fetcherOptions"];

export const fetchGetAnswersForReportV3 = (variables: GetAnswersForReportV3Variables, signal?: AbortSignal) =>
  formsFetch<Responses.Answers, GetAnswersForReportV3Error, undefined, {}, {}, GetAnswersForReportV3PathParams>({
    url: "/v3/reports/{reportId}/answers",
    method: "get",
    ...variables,
    signal
  });

export const useGetAnswersForReportV3 = <TData = Responses.Answers>(
  variables: GetAnswersForReportV3Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.Answers, GetAnswersForReportV3Error, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<Responses.Answers, GetAnswersForReportV3Error, TData>(
    queryKeyFn({ path: "/v3/reports/{reportId}/answers", operationId: "getAnswersForReportV3", variables }),
    ({ signal }) => fetchGetAnswersForReportV3({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PostAnswersForReportV3PathParams = {
  reportId: string;
};

export type PostAnswersForReportV3Error = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.Error;
    }
  | {
      status: 401;
      payload: Responses.Error;
    }
>;

export type PostAnswersForReportV3Variables = {
  pathParams: PostAnswersForReportV3PathParams;
} & FormsContext["fetcherOptions"];

export const fetchPostAnswersForReportV3 = (variables: PostAnswersForReportV3Variables, signal?: AbortSignal) =>
  formsFetch<Responses.Answers, PostAnswersForReportV3Error, undefined, {}, {}, PostAnswersForReportV3PathParams>({
    url: "/v3/reports/{reportId}/answers",
    method: "post",
    ...variables,
    signal
  });

export const usePostAnswersForReportV3 = (
  options?: Omit<
    reactQuery.UseMutationOptions<Responses.Answers, PostAnswersForReportV3Error, PostAnswersForReportV3Variables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<Responses.Answers, PostAnswersForReportV3Error, PostAnswersForReportV3Variables>(
    (variables: PostAnswersForReportV3Variables) => fetchPostAnswersForReportV3({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetAnswersForReportAndAreaPathParams = {
  reportId: string;
  areaId: string;
};

export type GetAnswersForReportAndAreaQueryParams = {
  /**
   * restricts returned answers to ONLY user answers when true
   */
  restricted?: string;
};

export type GetAnswersForReportAndAreaError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetAnswersForReportAndAreaVariables = {
  pathParams: GetAnswersForReportAndAreaPathParams;
  queryParams?: GetAnswersForReportAndAreaQueryParams;
} & FormsContext["fetcherOptions"];

/**
 * Returns all answers a user, and members of the user's teams, have created for this area of interest. Only returns user answers when optional "restricted" query param is true
 */
export const fetchGetAnswersForReportAndArea = (variables: GetAnswersForReportAndAreaVariables, signal?: AbortSignal) =>
  formsFetch<
    Responses.Answers,
    GetAnswersForReportAndAreaError,
    undefined,
    {},
    GetAnswersForReportAndAreaQueryParams,
    GetAnswersForReportAndAreaPathParams
  >({ url: "/v3/reports/{reportId}/answers/area/{areaId}", method: "get", ...variables, signal });

/**
 * Returns all answers a user, and members of the user's teams, have created for this area of interest. Only returns user answers when optional "restricted" query param is true
 */
export const useGetAnswersForReportAndArea = <TData = Responses.Answers>(
  variables: GetAnswersForReportAndAreaVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.Answers, GetAnswersForReportAndAreaError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<Responses.Answers, GetAnswersForReportAndAreaError, TData>(
    queryKeyFn({
      path: "/v3/reports/{reportId}/answers/area/{areaId}",
      operationId: "getAnswersForReportAndArea",
      variables
    }),
    ({ signal }) => fetchGetAnswersForReportAndArea({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetAnswerForReportPathParams = {
  reportId: string;
  id: string;
};

export type GetAnswerForReportError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetAnswerForReportVariables = {
  pathParams: GetAnswerForReportPathParams;
} & FormsContext["fetcherOptions"];

export const fetchGetAnswerForReport = (variables: GetAnswerForReportVariables, signal?: AbortSignal) =>
  formsFetch<Responses.Answer, GetAnswerForReportError, undefined, {}, {}, GetAnswerForReportPathParams>({
    url: "/v1/reports/{reportId}/answers/{id}",
    method: "get",
    ...variables,
    signal
  });

export const useGetAnswerForReport = <TData = Responses.Answer>(
  variables: GetAnswerForReportVariables,
  options?: Omit<reactQuery.UseQueryOptions<Responses.Answer, GetAnswerForReportError, TData>, "queryKey" | "queryFn">
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<Responses.Answer, GetAnswerForReportError, TData>(
    queryKeyFn({ path: "/v1/reports/{reportId}/answers/{id}", operationId: "getAnswerForReport", variables }),
    ({ signal }) => fetchGetAnswerForReport({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PatchAwnserForReportPathParams = {
  reportId: string;
  id: string;
};

export type PatchAwnserForReportError = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.Error;
}>;

export type PatchAwnserForReportVariables = {
  pathParams: PatchAwnserForReportPathParams;
} & FormsContext["fetcherOptions"];

export const fetchPatchAwnserForReport = (variables: PatchAwnserForReportVariables, signal?: AbortSignal) =>
  formsFetch<undefined, PatchAwnserForReportError, undefined, {}, {}, PatchAwnserForReportPathParams>({
    url: "/v1/reports/{reportId}/answers/{id}",
    method: "patch",
    ...variables,
    signal
  });

export const usePatchAwnserForReport = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, PatchAwnserForReportError, PatchAwnserForReportVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<undefined, PatchAwnserForReportError, PatchAwnserForReportVariables>(
    (variables: PatchAwnserForReportVariables) => fetchPatchAwnserForReport({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DeleteAnswerForReportPathParams = {
  reportId: string;
  id: string;
};

export type DeleteAnswerForReportError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type DeleteAnswerForReportVariables = {
  pathParams: DeleteAnswerForReportPathParams;
} & FormsContext["fetcherOptions"];

export const fetchDeleteAnswerForReport = (variables: DeleteAnswerForReportVariables, signal?: AbortSignal) =>
  formsFetch<undefined, DeleteAnswerForReportError, undefined, {}, {}, DeleteAnswerForReportPathParams>({
    url: "/v1/reports/{reportId}/answers/{id}",
    method: "delete",
    ...variables,
    signal
  });

export const useDeleteAnswerForReport = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteAnswerForReportError, DeleteAnswerForReportVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<undefined, DeleteAnswerForReportError, DeleteAnswerForReportVariables>(
    (variables: DeleteAnswerForReportVariables) => fetchDeleteAnswerForReport({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetAnswerForReportV3PathParams = {
  reportId: string;
  id: string;
};

export type GetAnswerForReportV3Error = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetAnswerForReportV3Variables = {
  pathParams: GetAnswerForReportV3PathParams;
} & FormsContext["fetcherOptions"];

export const fetchGetAnswerForReportV3 = (variables: GetAnswerForReportV3Variables, signal?: AbortSignal) =>
  formsFetch<Responses.Answers, GetAnswerForReportV3Error, undefined, {}, {}, GetAnswerForReportV3PathParams>({
    url: "/v3/reports/{reportId}/answers/{id}",
    method: "get",
    ...variables,
    signal
  });

export const useGetAnswerForReportV3 = <TData = Responses.Answers>(
  variables: GetAnswerForReportV3Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.Answers, GetAnswerForReportV3Error, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<Responses.Answers, GetAnswerForReportV3Error, TData>(
    queryKeyFn({ path: "/v3/reports/{reportId}/answers/{id}", operationId: "getAnswerForReportV3", variables }),
    ({ signal }) => fetchGetAnswerForReportV3({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PatchAwnserForReportV3PathParams = {
  reportId: string;
  id: string;
};

export type PatchAwnserForReportV3Error = Fetcher.ErrorWrapper<{
  status: 500;
  payload: Responses.Error;
}>;

export type PatchAwnserForReportV3Variables = {
  pathParams: PatchAwnserForReportV3PathParams;
} & FormsContext["fetcherOptions"];

export const fetchPatchAwnserForReportV3 = (variables: PatchAwnserForReportV3Variables, signal?: AbortSignal) =>
  formsFetch<undefined, PatchAwnserForReportV3Error, undefined, {}, {}, PatchAwnserForReportV3PathParams>({
    url: "/v3/reports/{reportId}/answers/{id}",
    method: "patch",
    ...variables,
    signal
  });

export const usePatchAwnserForReportV3 = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, PatchAwnserForReportV3Error, PatchAwnserForReportV3Variables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<undefined, PatchAwnserForReportV3Error, PatchAwnserForReportV3Variables>(
    (variables: PatchAwnserForReportV3Variables) => fetchPatchAwnserForReportV3({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DeleteAnswerForReportV3PathParams = {
  reportId: string;
  id: string;
};

export type DeleteAnswerForReportV3Error = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type DeleteAnswerForReportV3Variables = {
  pathParams: DeleteAnswerForReportV3PathParams;
} & FormsContext["fetcherOptions"];

export const fetchDeleteAnswerForReportV3 = (variables: DeleteAnswerForReportV3Variables, signal?: AbortSignal) =>
  formsFetch<undefined, DeleteAnswerForReportV3Error, undefined, {}, {}, DeleteAnswerForReportV3PathParams>({
    url: "/v3/reports/{reportId}/answers/{id}",
    method: "delete",
    ...variables,
    signal
  });

export const useDeleteAnswerForReportV3 = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteAnswerForReportV3Error, DeleteAnswerForReportV3Variables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<undefined, DeleteAnswerForReportV3Error, DeleteAnswerForReportV3Variables>(
    (variables: DeleteAnswerForReportV3Variables) => fetchDeleteAnswerForReportV3({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetAllQuestionnairesError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type GetAllQuestionnairesVariables = FormsContext["fetcherOptions"];

export const fetchGetAllQuestionnaires = (variables: GetAllQuestionnairesVariables, signal?: AbortSignal) =>
  formsFetch<Responses.Questionnaires, GetAllQuestionnairesError, undefined, {}, {}, {}>({
    url: "/v1/questionnaire",
    method: "get",
    ...variables,
    signal
  });

export const useGetAllQuestionnaires = <TData = Responses.Questionnaires>(
  variables: GetAllQuestionnairesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.Questionnaires, GetAllQuestionnairesError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<Responses.Questionnaires, GetAllQuestionnairesError, TData>(
    queryKeyFn({ path: "/v1/questionnaire", operationId: "getAllQuestionnaires", variables }),
    ({ signal }) => fetchGetAllQuestionnaires({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PostQuestionnaireError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type PostQuestionnaireVariables = FormsContext["fetcherOptions"];

export const fetchPostQuestionnaire = (variables: PostQuestionnaireVariables, signal?: AbortSignal) =>
  formsFetch<Responses.Questionnaire, PostQuestionnaireError, undefined, {}, {}, {}>({
    url: "/v1/questionnaire",
    method: "post",
    ...variables,
    signal
  });

export const usePostQuestionnaire = (
  options?: Omit<
    reactQuery.UseMutationOptions<Responses.Questionnaire, PostQuestionnaireError, PostQuestionnaireVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<Responses.Questionnaire, PostQuestionnaireError, PostQuestionnaireVariables>(
    (variables: PostQuestionnaireVariables) => fetchPostQuestionnaire({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetQuestionnairePathParams = {
  /**
   * Questionnaire ObjectId
   */
  id: string;
};

export type GetQuestionnaireError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetQuestionnaireVariables = {
  pathParams: GetQuestionnairePathParams;
} & FormsContext["fetcherOptions"];

export const fetchGetQuestionnaire = (variables: GetQuestionnaireVariables, signal?: AbortSignal) =>
  formsFetch<Responses.Questionnaire, GetQuestionnaireError, undefined, {}, {}, GetQuestionnairePathParams>({
    url: "/v1/questionnaire/{id}",
    method: "get",
    ...variables,
    signal
  });

export const useGetQuestionnaire = <TData = Responses.Questionnaire>(
  variables: GetQuestionnaireVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.Questionnaire, GetQuestionnaireError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<Responses.Questionnaire, GetQuestionnaireError, TData>(
    queryKeyFn({ path: "/v1/questionnaire/{id}", operationId: "getQuestionnaire", variables }),
    ({ signal }) => fetchGetQuestionnaire({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PatchQuestionnaireIdPathParams = {
  /**
   * Questionnaire ObjectId
   */
  id: string;
};

export type PatchQuestionnaireIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type PatchQuestionnaireIdVariables = {
  pathParams: PatchQuestionnaireIdPathParams;
} & FormsContext["fetcherOptions"];

export const fetchPatchQuestionnaireId = (variables: PatchQuestionnaireIdVariables, signal?: AbortSignal) =>
  formsFetch<Responses.Questionnaire, PatchQuestionnaireIdError, undefined, {}, {}, PatchQuestionnaireIdPathParams>({
    url: "/v1/questionnaire/{id}",
    method: "patch",
    ...variables,
    signal
  });

export const usePatchQuestionnaireId = (
  options?: Omit<
    reactQuery.UseMutationOptions<Responses.Questionnaire, PatchQuestionnaireIdError, PatchQuestionnaireIdVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<Responses.Questionnaire, PatchQuestionnaireIdError, PatchQuestionnaireIdVariables>(
    (variables: PatchQuestionnaireIdVariables) => fetchPatchQuestionnaireId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DeleteQuestionnairePathParams = {
  /**
   * Questionnaire ObjectId
   */
  id: string;
};

export type DeleteQuestionnaireError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type DeleteQuestionnaireVariables = {
  pathParams: DeleteQuestionnairePathParams;
} & FormsContext["fetcherOptions"];

export const fetchDeleteQuestionnaire = (variables: DeleteQuestionnaireVariables, signal?: AbortSignal) =>
  formsFetch<undefined, DeleteQuestionnaireError, undefined, {}, {}, DeleteQuestionnairePathParams>({
    url: "/v1/questionnaire/{id}",
    method: "delete",
    ...variables,
    signal
  });

export const useDeleteQuestionnaire = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteQuestionnaireError, DeleteQuestionnaireVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<undefined, DeleteQuestionnaireError, DeleteQuestionnaireVariables>(
    (variables: DeleteQuestionnaireVariables) => fetchDeleteQuestionnaire({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetQuestionnaireCsvPathParams = {
  id: string;
};

export type GetQuestionnaireCsvError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetQuestionnaireCsvVariables = {
  pathParams: GetQuestionnaireCsvPathParams;
} & FormsContext["fetcherOptions"];

/**
 * Download the given questionnaire as a CSV file
 */
export const fetchGetQuestionnaireCsv = (variables: GetQuestionnaireCsvVariables, signal?: AbortSignal) =>
  formsFetch<undefined, GetQuestionnaireCsvError, undefined, {}, {}, GetQuestionnaireCsvPathParams>({
    url: "/v1/questionnaire/{id}/download-answers",
    method: "get",
    ...variables,
    signal
  });

/**
 * Download the given questionnaire as a CSV file
 */
export const useGetQuestionnaireCsv = <TData = undefined>(
  variables: GetQuestionnaireCsvVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, GetQuestionnaireCsvError, TData>, "queryKey" | "queryFn">
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<undefined, GetQuestionnaireCsvError, TData>(
    queryKeyFn({ path: "/v1/questionnaire/{id}/download-answers", operationId: "getQuestionnaireCsv", variables }),
    ({ signal }) => fetchGetQuestionnaireCsv({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetAllAnswersForQuestionnairePathParams = {
  questionnaireId: string;
};

export type GetAllAnswersForQuestionnaireError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetAllAnswersForQuestionnaireVariables = {
  pathParams: GetAllAnswersForQuestionnairePathParams;
} & FormsContext["fetcherOptions"];

export const fetchGetAllAnswersForQuestionnaire = (
  variables: GetAllAnswersForQuestionnaireVariables,
  signal?: AbortSignal
) =>
  formsFetch<
    Responses.Responses,
    GetAllAnswersForQuestionnaireError,
    undefined,
    {},
    {},
    GetAllAnswersForQuestionnairePathParams
  >({ url: "/v1/questionnaire/{questionnaireId}/answer", method: "get", ...variables, signal });

export const useGetAllAnswersForQuestionnaire = <TData = Responses.Responses>(
  variables: GetAllAnswersForQuestionnaireVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.Responses, GetAllAnswersForQuestionnaireError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<Responses.Responses, GetAllAnswersForQuestionnaireError, TData>(
    queryKeyFn({
      path: "/v1/questionnaire/{questionnaireId}/answer",
      operationId: "getAllAnswersForQuestionnaire",
      variables
    }),
    ({ signal }) => fetchGetAllAnswersForQuestionnaire({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PostAnswerForQuestionnairePathParams = {
  questionnaireId: string;
};

export type PostAnswerForQuestionnaireError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.Error;
    }
  | {
      status: 401;
      payload: Responses.Error;
    }
>;

export type PostAnswerForQuestionnaireVariables = {
  pathParams: PostAnswerForQuestionnairePathParams;
} & FormsContext["fetcherOptions"];

export const fetchPostAnswerForQuestionnaire = (variables: PostAnswerForQuestionnaireVariables, signal?: AbortSignal) =>
  formsFetch<
    Responses.Response,
    PostAnswerForQuestionnaireError,
    undefined,
    {},
    {},
    PostAnswerForQuestionnairePathParams
  >({ url: "/v1/questionnaire/{questionnaireId}/answer", method: "post", ...variables, signal });

export const usePostAnswerForQuestionnaire = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.Response,
      PostAnswerForQuestionnaireError,
      PostAnswerForQuestionnaireVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<
    Responses.Response,
    PostAnswerForQuestionnaireError,
    PostAnswerForQuestionnaireVariables
  >(
    (variables: PostAnswerForQuestionnaireVariables) =>
      fetchPostAnswerForQuestionnaire({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetAnswersForQuestionnairePathParams = {
  id: string;
  questionnaireId: string;
};

export type GetAnswersForQuestionnaireError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetAnswersForQuestionnaireVariables = {
  pathParams: GetAnswersForQuestionnairePathParams;
} & FormsContext["fetcherOptions"];

export const fetchGetAnswersForQuestionnaire = (variables: GetAnswersForQuestionnaireVariables, signal?: AbortSignal) =>
  formsFetch<
    Responses.Response,
    GetAnswersForQuestionnaireError,
    undefined,
    {},
    {},
    GetAnswersForQuestionnairePathParams
  >({ url: "/v1/questionnaire/{questionnaireId}/answer/{id}", method: "get", ...variables, signal });

export const useGetAnswersForQuestionnaire = <TData = Responses.Response>(
  variables: GetAnswersForQuestionnaireVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.Response, GetAnswersForQuestionnaireError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<Responses.Response, GetAnswersForQuestionnaireError, TData>(
    queryKeyFn({
      path: "/v1/questionnaire/{questionnaireId}/answer/{id}",
      operationId: "getAnswersForQuestionnaire",
      variables
    }),
    ({ signal }) => fetchGetAnswersForQuestionnaire({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PatchAwnsersForQuestionnairePathParams = {
  id: string;
  questionnaireId: string;
};

export type PatchAwnsersForQuestionnaireError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type PatchAwnsersForQuestionnaireVariables = {
  pathParams: PatchAwnsersForQuestionnairePathParams;
} & FormsContext["fetcherOptions"];

export const fetchPatchAwnsersForQuestionnaire = (
  variables: PatchAwnsersForQuestionnaireVariables,
  signal?: AbortSignal
) =>
  formsFetch<
    Responses.Response,
    PatchAwnsersForQuestionnaireError,
    undefined,
    {},
    {},
    PatchAwnsersForQuestionnairePathParams
  >({ url: "/v1/questionnaire/{questionnaireId}/answer/{id}", method: "patch", ...variables, signal });

export const usePatchAwnsersForQuestionnaire = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.Response,
      PatchAwnsersForQuestionnaireError,
      PatchAwnsersForQuestionnaireVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<
    Responses.Response,
    PatchAwnsersForQuestionnaireError,
    PatchAwnsersForQuestionnaireVariables
  >(
    (variables: PatchAwnsersForQuestionnaireVariables) =>
      fetchPatchAwnsersForQuestionnaire({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DeleteAwnsersForQuestionnairePathParams = {
  id: string;
  questionnaireId: string;
};

export type DeleteAwnsersForQuestionnaireError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type DeleteAwnsersForQuestionnaireVariables = {
  pathParams: DeleteAwnsersForQuestionnairePathParams;
} & FormsContext["fetcherOptions"];

export const fetchDeleteAwnsersForQuestionnaire = (
  variables: DeleteAwnsersForQuestionnaireVariables,
  signal?: AbortSignal
) =>
  formsFetch<undefined, DeleteAwnsersForQuestionnaireError, undefined, {}, {}, DeleteAwnsersForQuestionnairePathParams>(
    { url: "/v1/questionnaire/{questionnaireId}/answer/{id}", method: "delete", ...variables, signal }
  );

export const useDeleteAwnsersForQuestionnaire = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteAwnsersForQuestionnaireError,
      DeleteAwnsersForQuestionnaireVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useFormsContext();
  return reactQuery.useMutation<undefined, DeleteAwnsersForQuestionnaireError, DeleteAwnsersForQuestionnaireVariables>(
    (variables: DeleteAwnsersForQuestionnaireVariables) =>
      fetchDeleteAwnsersForQuestionnaire({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetFwFormsHealthcheckError = Fetcher.ErrorWrapper<undefined>;

export type GetFwFormsHealthcheckVariables = FormsContext["fetcherOptions"];

export const fetchGetFwFormsHealthcheck = (variables: GetFwFormsHealthcheckVariables, signal?: AbortSignal) =>
  formsFetch<Responses.Healthcheck, GetFwFormsHealthcheckError, undefined, {}, {}, {}>({
    url: "/v1/fw_forms/healthcheck",
    method: "get",
    ...variables,
    signal
  });

export const useGetFwFormsHealthcheck = <TData = Responses.Healthcheck>(
  variables: GetFwFormsHealthcheckVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.Healthcheck, GetFwFormsHealthcheckError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useFormsContext(options);
  return reactQuery.useQuery<Responses.Healthcheck, GetFwFormsHealthcheckError, TData>(
    queryKeyFn({ path: "/v1/fw_forms/healthcheck", operationId: "getFwFormsHealthcheck", variables }),
    ({ signal }) => fetchGetFwFormsHealthcheck({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type QueryOperation =
  | {
      path: "/v1/reports/{reportId}";
      operationId: "getReport";
      variables: GetReportVariables;
    }
  | {
      path: "/v1/reports";
      operationId: "getAllReports";
      variables: GetAllReportsVariables;
    }
  | {
      path: "/v3/reports/getAllAnswersForUser";
      operationId: "getAllAnswersForUser";
      variables: GetAllAnswersForUserVariables;
    }
  | {
      path: "/v1/reports/{reportId}/download-answers";
      operationId: "getReportCsv";
      variables: GetReportCsvVariables;
    }
  | {
      path: "/v1/reports/{reportId}/answers";
      operationId: "getAnswersForReport";
      variables: GetAnswersForReportVariables;
    }
  | {
      path: "/v3/reports/{reportId}/answers";
      operationId: "getAnswersForReportV3";
      variables: GetAnswersForReportV3Variables;
    }
  | {
      path: "/v3/reports/{reportId}/answers/area/{areaId}";
      operationId: "getAnswersForReportAndArea";
      variables: GetAnswersForReportAndAreaVariables;
    }
  | {
      path: "/v1/reports/{reportId}/answers/{id}";
      operationId: "getAnswerForReport";
      variables: GetAnswerForReportVariables;
    }
  | {
      path: "/v3/reports/{reportId}/answers/{id}";
      operationId: "getAnswerForReportV3";
      variables: GetAnswerForReportV3Variables;
    }
  | {
      path: "/v1/questionnaire";
      operationId: "getAllQuestionnaires";
      variables: GetAllQuestionnairesVariables;
    }
  | {
      path: "/v1/questionnaire/{id}";
      operationId: "getQuestionnaire";
      variables: GetQuestionnaireVariables;
    }
  | {
      path: "/v1/questionnaire/{id}/download-answers";
      operationId: "getQuestionnaireCsv";
      variables: GetQuestionnaireCsvVariables;
    }
  | {
      path: "/v1/questionnaire/{questionnaireId}/answer";
      operationId: "getAllAnswersForQuestionnaire";
      variables: GetAllAnswersForQuestionnaireVariables;
    }
  | {
      path: "/v1/questionnaire/{questionnaireId}/answer/{id}";
      operationId: "getAnswersForQuestionnaire";
      variables: GetAnswersForQuestionnaireVariables;
    }
  | {
      path: "/v1/fw_forms/healthcheck";
      operationId: "getFwFormsHealthcheck";
      variables: GetFwFormsHealthcheckVariables;
    };
