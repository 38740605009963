export const CATEGORY = {
  CONTEXTUAL_LAYERS: "Contextual layers",
  TEAM: "Team",
  AREA_CREATION: "Create an area",
  TEMPLATES: "Templates",
  REPORTS: "Reports",
  SETTINGS: "Settings"
};

export const ACTION = {
  AREA_SAVE: "Save area",
  TEMPLATE_SAVE: "Save template",
  UPLOAD_SHAPEFILE: "Upload shapefile",
  NEW_TEMPLATE: "Create new template",
  PUBLISH_TEMPLATE: "Set as published",
  DOWNLOAD_REPORT_DATA: "Download report answers",
  EDIT: "Edit",
  EDIT_SETTINGS: "Edit settings",
  ADD_TEAM: "Add team member",
  REMOVE_TEAM: "Delete team member",
  ADD_GFW_LAYER: "Add GFW layers",
  ADD_CUSTOM_LAYER: "Add custom layers"
};

const Analytics = { CATEGORY, ACTION };

export default Analytics;
