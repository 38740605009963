/**
 * Generated by @openapi-codegen
 *
 * @version 1.0
 */
import * as reactQuery from "@tanstack/react-query";
import { useCoreContext, CoreContext } from "./coreContext";
import type * as Fetcher from "./coreFetcher";
import { coreFetch } from "./coreFetcher";
import type * as RequestBodies from "./coreRequestBodies";
import type * as Responses from "./coreResponses";

export type PostV3GfwAssignmentsError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type PostV3GfwAssignmentsVariables = {
  body: RequestBodies.CreateAssignmentBody;
} & CoreContext["fetcherOptions"];

/**
 * Creates an assignment
 */
export const fetchPostV3GfwAssignments = (variables: PostV3GfwAssignmentsVariables, signal?: AbortSignal) =>
  coreFetch<Responses.AssignmentResponse, PostV3GfwAssignmentsError, RequestBodies.CreateAssignmentBody, {}, {}, {}>({
    url: "/v3/gfw/assignments",
    method: "post",
    ...variables,
    signal
  });

/**
 * Creates an assignment
 */
export const usePostV3GfwAssignments = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.AssignmentResponse,
      PostV3GfwAssignmentsError,
      PostV3GfwAssignmentsVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<Responses.AssignmentResponse, PostV3GfwAssignmentsError, PostV3GfwAssignmentsVariables>(
    (variables: PostV3GfwAssignmentsVariables) => fetchPostV3GfwAssignments({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetV3GfwUserError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type GetV3GfwUserVariables = CoreContext["fetcherOptions"];

/**
 * Returns a user's assignments
 */
export const fetchGetV3GfwUser = (variables: GetV3GfwUserVariables, signal?: AbortSignal) =>
  coreFetch<Responses.AssignmentsResponse, GetV3GfwUserError, undefined, {}, {}, {}>({
    url: "/v3/gfw/assignments/user",
    method: "get",
    ...variables,
    signal
  });

/**
 * Returns a user's assignments
 */
export const useGetV3GfwUser = <TData = Responses.AssignmentsResponse>(
  variables: GetV3GfwUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.AssignmentsResponse, GetV3GfwUserError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.AssignmentsResponse, GetV3GfwUserError, TData>(
    queryKeyFn({ path: "/v3/gfw/assignments/user", operationId: "getV3GfwUser", variables }),
    ({ signal }) => fetchGetV3GfwUser({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwAssignmentsOpenError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type GetV3GfwAssignmentsOpenVariables = CoreContext["fetcherOptions"];

/**
 * Returns all non-complete user assignments
 */
export const fetchGetV3GfwAssignmentsOpen = (variables: GetV3GfwAssignmentsOpenVariables, signal?: AbortSignal) =>
  coreFetch<Responses.AssignmentsResponse, GetV3GfwAssignmentsOpenError, undefined, {}, {}, {}>({
    url: "/v3/gfw/assignments/open",
    method: "get",
    ...variables,
    signal
  });

/**
 * Returns all non-complete user assignments
 */
export const useGetV3GfwAssignmentsOpen = <TData = Responses.AssignmentsResponse>(
  variables: GetV3GfwAssignmentsOpenVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.AssignmentsResponse, GetV3GfwAssignmentsOpenError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.AssignmentsResponse, GetV3GfwAssignmentsOpenError, TData>(
    queryKeyFn({ path: "/v3/gfw/assignments/open", operationId: "getV3GfwAssignmentsOpen", variables }),
    ({ signal }) => fetchGetV3GfwAssignmentsOpen({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwAssignmentsAreaAreaIdPathParams = {
  areaId: string;
};

export type GetV3GfwAssignmentsAreaAreaIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetV3GfwAssignmentsAreaAreaIdVariables = {
  pathParams: GetV3GfwAssignmentsAreaAreaIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Returns all user assignments within an area
 */
export const fetchGetV3GfwAssignmentsAreaAreaId = (
  variables: GetV3GfwAssignmentsAreaAreaIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.AssignmentsResponse,
    GetV3GfwAssignmentsAreaAreaIdError,
    undefined,
    {},
    {},
    GetV3GfwAssignmentsAreaAreaIdPathParams
  >({ url: "/v3/gfw/assignments/areas/{areaId}", method: "get", ...variables, signal });

/**
 * Returns all user assignments within an area
 */
export const useGetV3GfwAssignmentsAreaAreaId = <TData = Responses.AssignmentsResponse>(
  variables: GetV3GfwAssignmentsAreaAreaIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.AssignmentsResponse, GetV3GfwAssignmentsAreaAreaIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.AssignmentsResponse, GetV3GfwAssignmentsAreaAreaIdError, TData>(
    queryKeyFn({ path: "/v3/gfw/assignments/areas/{areaId}", operationId: "getV3GfwAssignmentsAreaAreaId", variables }),
    ({ signal }) => fetchGetV3GfwAssignmentsAreaAreaId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwAssignmentsAssignmentIdPathParams = {
  assignmentId: string;
};

export type GetV3GfwAssignmentsAssignmentIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetV3GfwAssignmentsAssignmentIdVariables = {
  pathParams: GetV3GfwAssignmentsAssignmentIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Gets a single assignment
 */
export const fetchGetV3GfwAssignmentsAssignmentId = (
  variables: GetV3GfwAssignmentsAssignmentIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.AssignmentResponse,
    GetV3GfwAssignmentsAssignmentIdError,
    undefined,
    {},
    {},
    GetV3GfwAssignmentsAssignmentIdPathParams
  >({ url: "/v3/gfw/assignments/{assignmentId}", method: "get", ...variables, signal });

/**
 * Gets a single assignment
 */
export const useGetV3GfwAssignmentsAssignmentId = <TData = Responses.AssignmentResponse>(
  variables: GetV3GfwAssignmentsAssignmentIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.AssignmentResponse, GetV3GfwAssignmentsAssignmentIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.AssignmentResponse, GetV3GfwAssignmentsAssignmentIdError, TData>(
    queryKeyFn({
      path: "/v3/gfw/assignments/{assignmentId}",
      operationId: "getV3GfwAssignmentsAssignmentId",
      variables
    }),
    ({ signal }) => fetchGetV3GfwAssignmentsAssignmentId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PatchV3GfwAssignmentsAssignmentIdPathParams = {
  assignmentId: string;
};

export type PatchV3GfwAssignmentsAssignmentIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
>;

export type PatchV3GfwAssignmentsAssignmentIdVariables = {
  body?: RequestBodies.UpdateAssignmentBody;
  pathParams: PatchV3GfwAssignmentsAssignmentIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Updates an assignment
 */
export const fetchPatchV3GfwAssignmentsAssignmentId = (
  variables: PatchV3GfwAssignmentsAssignmentIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.AssignmentResponse,
    PatchV3GfwAssignmentsAssignmentIdError,
    RequestBodies.UpdateAssignmentBody,
    {},
    {},
    PatchV3GfwAssignmentsAssignmentIdPathParams
  >({ url: "/v3/gfw/assignments/{assignmentId}", method: "patch", ...variables, signal });

/**
 * Updates an assignment
 */
export const usePatchV3GfwAssignmentsAssignmentId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.AssignmentResponse,
      PatchV3GfwAssignmentsAssignmentIdError,
      PatchV3GfwAssignmentsAssignmentIdVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    Responses.AssignmentResponse,
    PatchV3GfwAssignmentsAssignmentIdError,
    PatchV3GfwAssignmentsAssignmentIdVariables
  >(
    (variables: PatchV3GfwAssignmentsAssignmentIdVariables) =>
      fetchPatchV3GfwAssignmentsAssignmentId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DeleteV3GfwAssignmentsAssignmentIdPathParams = {
  assignmentId: string;
};

export type DeleteV3GfwAssignmentsAssignmentIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
>;

export type DeleteV3GfwAssignmentsAssignmentIdVariables = {
  pathParams: DeleteV3GfwAssignmentsAssignmentIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Deletes an assignment
 */
export const fetchDeleteV3GfwAssignmentsAssignmentId = (
  variables: DeleteV3GfwAssignmentsAssignmentIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    undefined,
    DeleteV3GfwAssignmentsAssignmentIdError,
    undefined,
    {},
    {},
    DeleteV3GfwAssignmentsAssignmentIdPathParams
  >({ url: "/v3/gfw/assignments/{assignmentId}", method: "delete", ...variables, signal });

/**
 * Deletes an assignment
 */
export const useDeleteV3GfwAssignmentsAssignmentId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteV3GfwAssignmentsAssignmentIdError,
      DeleteV3GfwAssignmentsAssignmentIdVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    undefined,
    DeleteV3GfwAssignmentsAssignmentIdError,
    DeleteV3GfwAssignmentsAssignmentIdVariables
  >(
    (variables: DeleteV3GfwAssignmentsAssignmentIdVariables) =>
      fetchDeleteV3GfwAssignmentsAssignmentId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type PostV3GfwRoutesSyncError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type PostV3GfwRoutesSyncVariables = {
  body?: RequestBodies.RouteBody;
} & CoreContext["fetcherOptions"];

/**
 * Syncs an array of routes to the database
 */
export const fetchPostV3GfwRoutesSync = (variables: PostV3GfwRoutesSyncVariables, signal?: AbortSignal) =>
  coreFetch<Responses.RoutesResponse, PostV3GfwRoutesSyncError, RequestBodies.RouteBody, {}, {}, {}>({
    url: "/v3/gfw/routes/sync",
    method: "post",
    ...variables,
    signal
  });

/**
 * Syncs an array of routes to the database
 */
export const usePostV3GfwRoutesSync = (
  options?: Omit<
    reactQuery.UseMutationOptions<Responses.RoutesResponse, PostV3GfwRoutesSyncError, PostV3GfwRoutesSyncVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<Responses.RoutesResponse, PostV3GfwRoutesSyncError, PostV3GfwRoutesSyncVariables>(
    (variables: PostV3GfwRoutesSyncVariables) => fetchPostV3GfwRoutesSync({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetV3GfwRoutesUserError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type GetV3GfwRoutesUserVariables = CoreContext["fetcherOptions"];

/**
 * Get all active routes made by the user
 */
export const fetchGetV3GfwRoutesUser = (variables: GetV3GfwRoutesUserVariables, signal?: AbortSignal) =>
  coreFetch<Responses.RoutesResponse, GetV3GfwRoutesUserError, undefined, {}, {}, {}>({
    url: "/v3/gfw/routes/user",
    method: "get",
    ...variables,
    signal
  });

/**
 * Get all active routes made by the user
 */
export const useGetV3GfwRoutesUser = <TData = Responses.RoutesResponse>(
  variables: GetV3GfwRoutesUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.RoutesResponse, GetV3GfwRoutesUserError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.RoutesResponse, GetV3GfwRoutesUserError, TData>(
    queryKeyFn({ path: "/v3/gfw/routes/user", operationId: "getV3GfwRoutesUser", variables }),
    ({ signal }) => fetchGetV3GfwRoutesUser({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwRoutesTeamsError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type GetV3GfwRoutesTeamsVariables = CoreContext["fetcherOptions"];

/**
 * Returns an array of all active routes linked to the user's teams
 */
export const fetchGetV3GfwRoutesTeams = (variables: GetV3GfwRoutesTeamsVariables, signal?: AbortSignal) =>
  coreFetch<Responses.RoutesResponse, GetV3GfwRoutesTeamsError, undefined, {}, {}, {}>({
    url: "/v3/gfw/routes/teams",
    method: "get",
    ...variables,
    signal
  });

/**
 * Returns an array of all active routes linked to the user's teams
 */
export const useGetV3GfwRoutesTeams = <TData = Responses.RoutesResponse>(
  variables: GetV3GfwRoutesTeamsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.RoutesResponse, GetV3GfwRoutesTeamsError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.RoutesResponse, GetV3GfwRoutesTeamsError, TData>(
    queryKeyFn({ path: "/v3/gfw/routes/teams", operationId: "getV3GfwRoutesTeams", variables }),
    ({ signal }) => fetchGetV3GfwRoutesTeams({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwRoutesRouteIdPathParams = {
  routeId: string;
};

export type GetV3GfwRoutesRouteIdError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type GetV3GfwRoutesRouteIdVariables = {
  pathParams: GetV3GfwRoutesRouteIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Returns the route with the given id
 */
export const fetchGetV3GfwRoutesRouteId = (variables: GetV3GfwRoutesRouteIdVariables, signal?: AbortSignal) =>
  coreFetch<Responses.RouteResponse, GetV3GfwRoutesRouteIdError, undefined, {}, {}, GetV3GfwRoutesRouteIdPathParams>({
    url: "/v3/gfw/routes/{routeId}",
    method: "get",
    ...variables,
    signal
  });

/**
 * Returns the route with the given id
 */
export const useGetV3GfwRoutesRouteId = <TData = Responses.RouteResponse>(
  variables: GetV3GfwRoutesRouteIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.RouteResponse, GetV3GfwRoutesRouteIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.RouteResponse, GetV3GfwRoutesRouteIdError, TData>(
    queryKeyFn({ path: "/v3/gfw/routes/{routeId}", operationId: "getV3GfwRoutesRouteId", variables }),
    ({ signal }) => fetchGetV3GfwRoutesRouteId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DeleteV3GfwRoutesRouteIdPathParams = {
  routeId: string;
};

export type DeleteV3GfwRoutesRouteIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type DeleteV3GfwRoutesRouteIdVariables = {
  pathParams: DeleteV3GfwRoutesRouteIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Sets a route's status to inactive
 */
export const fetchDeleteV3GfwRoutesRouteId = (variables: DeleteV3GfwRoutesRouteIdVariables, signal?: AbortSignal) =>
  coreFetch<undefined, DeleteV3GfwRoutesRouteIdError, undefined, {}, {}, DeleteV3GfwRoutesRouteIdPathParams>({
    url: "/v3/gfw/routes/{routeId}",
    method: "delete",
    ...variables,
    signal
  });

/**
 * Sets a route's status to inactive
 */
export const useDeleteV3GfwRoutesRouteId = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteV3GfwRoutesRouteIdError, DeleteV3GfwRoutesRouteIdVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<undefined, DeleteV3GfwRoutesRouteIdError, DeleteV3GfwRoutesRouteIdVariables>(
    (variables: DeleteV3GfwRoutesRouteIdVariables) =>
      fetchDeleteV3GfwRoutesRouteId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type PostV3GfwTemplatesError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
>;

export type PostV3GfwTemplatesVariables = {
  body: RequestBodies.CreateTemplateBody;
} & CoreContext["fetcherOptions"];

/**
 * Creates a new template
 */
export const fetchPostV3GfwTemplates = (variables: PostV3GfwTemplatesVariables, signal?: AbortSignal) =>
  coreFetch<Responses.TemplateResponse, PostV3GfwTemplatesError, RequestBodies.CreateTemplateBody, {}, {}, {}>({
    url: "/v3/gfw/templates",
    method: "post",
    ...variables,
    signal
  });

/**
 * Creates a new template
 */
export const usePostV3GfwTemplates = (
  options?: Omit<
    reactQuery.UseMutationOptions<Responses.TemplateResponse, PostV3GfwTemplatesError, PostV3GfwTemplatesVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<Responses.TemplateResponse, PostV3GfwTemplatesError, PostV3GfwTemplatesVariables>(
    (variables: PostV3GfwTemplatesVariables) => fetchPostV3GfwTemplates({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetV3GfwTemplatesError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type GetV3GfwTemplatesVariables = CoreContext["fetcherOptions"];

/**
 * Returns all user and public templates
 */
export const fetchGetV3GfwTemplates = (variables: GetV3GfwTemplatesVariables, signal?: AbortSignal) =>
  coreFetch<Responses.TemplatesResponse, GetV3GfwTemplatesError, undefined, {}, {}, {}>({
    url: "/v3/gfw/templates",
    method: "get",
    ...variables,
    signal
  });

/**
 * Returns all user and public templates
 */
export const useGetV3GfwTemplates = <TData = Responses.TemplatesResponse>(
  variables: GetV3GfwTemplatesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.TemplatesResponse, GetV3GfwTemplatesError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.TemplatesResponse, GetV3GfwTemplatesError, TData>(
    queryKeyFn({ path: "/v3/gfw/templates", operationId: "getV3GfwTemplates", variables }),
    ({ signal }) => fetchGetV3GfwTemplates({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwTemplatesTemplateIdPathParams = {
  templateId: string;
};

export type GetV3GfwTemplatesTemplateIdError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type GetV3GfwTemplatesTemplateIdVariables = {
  pathParams: GetV3GfwTemplatesTemplateIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Gets a single template given the template id
 */
export const fetchGetV3GfwTemplatesTemplateId = (
  variables: GetV3GfwTemplatesTemplateIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.TemplateResponse,
    GetV3GfwTemplatesTemplateIdError,
    undefined,
    {},
    {},
    GetV3GfwTemplatesTemplateIdPathParams
  >({ url: "/v3/gfw/templates/{templateId}", method: "get", ...variables, signal });

/**
 * Gets a single template given the template id
 */
export const useGetV3GfwTemplatesTemplateId = <TData = Responses.TemplateResponse>(
  variables: GetV3GfwTemplatesTemplateIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.TemplateResponse, GetV3GfwTemplatesTemplateIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.TemplateResponse, GetV3GfwTemplatesTemplateIdError, TData>(
    queryKeyFn({ path: "/v3/gfw/templates/{templateId}", operationId: "getV3GfwTemplatesTemplateId", variables }),
    ({ signal }) => fetchGetV3GfwTemplatesTemplateId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PatchV3GfwTemplatesTemplateIdPathParams = {
  templateId: string;
};

export type PatchV3GfwTemplatesTemplateIdError = Fetcher.ErrorWrapper<undefined>;

export type PatchV3GfwTemplatesTemplateIdVariables = {
  body?: RequestBodies.UpdateTemplateBody;
  pathParams: PatchV3GfwTemplatesTemplateIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Creates a new version of the template that shares the same `editGroupId` with the template.
 */
export const fetchPatchV3GfwTemplatesTemplateId = (
  variables: PatchV3GfwTemplatesTemplateIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.TemplateResponse,
    PatchV3GfwTemplatesTemplateIdError,
    RequestBodies.UpdateTemplateBody,
    {},
    {},
    PatchV3GfwTemplatesTemplateIdPathParams
  >({ url: "/v3/gfw/templates/{templateId}", method: "patch", ...variables, signal });

/**
 * Creates a new version of the template that shares the same `editGroupId` with the template.
 */
export const usePatchV3GfwTemplatesTemplateId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.TemplateResponse,
      PatchV3GfwTemplatesTemplateIdError,
      PatchV3GfwTemplatesTemplateIdVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    Responses.TemplateResponse,
    PatchV3GfwTemplatesTemplateIdError,
    PatchV3GfwTemplatesTemplateIdVariables
  >(
    (variables: PatchV3GfwTemplatesTemplateIdVariables) =>
      fetchPatchV3GfwTemplatesTemplateId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DeleteV3GfwTemplatesTemplateIdPathParams = {
  templateId: string;
};

export type DeleteV3GfwTemplatesTemplateIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
>;

export type DeleteV3GfwTemplatesTemplateIdVariables = {
  pathParams: DeleteV3GfwTemplatesTemplateIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Deletes a template with the given id
 */
export const fetchDeleteV3GfwTemplatesTemplateId = (
  variables: DeleteV3GfwTemplatesTemplateIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    undefined,
    DeleteV3GfwTemplatesTemplateIdError,
    undefined,
    {},
    {},
    DeleteV3GfwTemplatesTemplateIdPathParams
  >({ url: "/v3/gfw/templates/{templateId}", method: "delete", ...variables, signal });

/**
 * Deletes a template with the given id
 */
export const useDeleteV3GfwTemplatesTemplateId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteV3GfwTemplatesTemplateIdError,
      DeleteV3GfwTemplatesTemplateIdVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    undefined,
    DeleteV3GfwTemplatesTemplateIdError,
    DeleteV3GfwTemplatesTemplateIdVariables
  >(
    (variables: DeleteV3GfwTemplatesTemplateIdVariables) =>
      fetchDeleteV3GfwTemplatesTemplateId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetV3GfwTemplatesTemplateIdAnswersPathParams = {
  templateId: string;
};

export type GetV3GfwTemplatesTemplateIdAnswersError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type GetV3GfwTemplatesTemplateIdAnswersVariables = {
  pathParams: GetV3GfwTemplatesTemplateIdAnswersPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Gets all answers user has permission to see for template with given id
 */
export const fetchGetV3GfwTemplatesTemplateIdAnswers = (
  variables: GetV3GfwTemplatesTemplateIdAnswersVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.AnswersResponse,
    GetV3GfwTemplatesTemplateIdAnswersError,
    undefined,
    {},
    {},
    GetV3GfwTemplatesTemplateIdAnswersPathParams
  >({ url: "/v3/gfw/templates/{templateId}/answers", method: "get", ...variables, signal });

/**
 * Gets all answers user has permission to see for template with given id
 */
export const useGetV3GfwTemplatesTemplateIdAnswers = <TData = Responses.AnswersResponse>(
  variables: GetV3GfwTemplatesTemplateIdAnswersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.AnswersResponse, GetV3GfwTemplatesTemplateIdAnswersError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.AnswersResponse, GetV3GfwTemplatesTemplateIdAnswersError, TData>(
    queryKeyFn({
      path: "/v3/gfw/templates/{templateId}/answers",
      operationId: "getV3GfwTemplatesTemplateIdAnswers",
      variables
    }),
    ({ signal }) => fetchGetV3GfwTemplatesTemplateIdAnswers({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PostV3GfwTemplatesTemplateIdAnswersPathParams = {
  templateId: string;
};

export type PostV3GfwTemplatesTemplateIdAnswersError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.Error;
    }
  | {
      status: 401;
      payload: Responses.Error;
    }
>;

export type PostV3GfwTemplatesTemplateIdAnswersVariables = {
  body: RequestBodies.CreateAnswerBody;
  pathParams: PostV3GfwTemplatesTemplateIdAnswersPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Creates a new answer
 */
export const fetchPostV3GfwTemplatesTemplateIdAnswers = (
  variables: PostV3GfwTemplatesTemplateIdAnswersVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.AnswerResponse,
    PostV3GfwTemplatesTemplateIdAnswersError,
    RequestBodies.CreateAnswerBody,
    {},
    {},
    PostV3GfwTemplatesTemplateIdAnswersPathParams
  >({ url: "/v3/gfw/templates/{templateId}/answers", method: "post", ...variables, signal });

/**
 * Creates a new answer
 */
export const usePostV3GfwTemplatesTemplateIdAnswers = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.AnswerResponse,
      PostV3GfwTemplatesTemplateIdAnswersError,
      PostV3GfwTemplatesTemplateIdAnswersVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    Responses.AnswerResponse,
    PostV3GfwTemplatesTemplateIdAnswersError,
    PostV3GfwTemplatesTemplateIdAnswersVariables
  >(
    (variables: PostV3GfwTemplatesTemplateIdAnswersVariables) =>
      fetchPostV3GfwTemplatesTemplateIdAnswers({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetV3GfwTemplatesTemplateIdAnswersAreaAreaIdPathParams = {
  templateId: string;
  areaId: string;
};

export type GetV3GfwTemplatesTemplateIdAnswersAreaAreaIdError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.Error;
}>;

export type GetV3GfwTemplatesTemplateIdAnswersAreaAreaIdVariables = {
  pathParams: GetV3GfwTemplatesTemplateIdAnswersAreaAreaIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Returns all answers associated with template and area with id provided
 */
export const fetchGetV3GfwTemplatesTemplateIdAnswersAreaAreaId = (
  variables: GetV3GfwTemplatesTemplateIdAnswersAreaAreaIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.AnswersResponse,
    GetV3GfwTemplatesTemplateIdAnswersAreaAreaIdError,
    undefined,
    {},
    {},
    GetV3GfwTemplatesTemplateIdAnswersAreaAreaIdPathParams
  >({ url: "/v3/gfw/templates/{templateId}/answers/area/{areaId}", method: "get", ...variables, signal });

/**
 * Returns all answers associated with template and area with id provided
 */
export const useGetV3GfwTemplatesTemplateIdAnswersAreaAreaId = <TData = Responses.AnswersResponse>(
  variables: GetV3GfwTemplatesTemplateIdAnswersAreaAreaIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.AnswersResponse, GetV3GfwTemplatesTemplateIdAnswersAreaAreaIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.AnswersResponse, GetV3GfwTemplatesTemplateIdAnswersAreaAreaIdError, TData>(
    queryKeyFn({
      path: "/v3/gfw/templates/{templateId}/answers/area/{areaId}",
      operationId: "getV3GfwTemplatesTemplateIdAnswersAreaAreaId",
      variables
    }),
    ({ signal }) => fetchGetV3GfwTemplatesTemplateIdAnswersAreaAreaId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwTemplatesTemplateIdAnswersAnswerIdPathParams = {
  templateId: string;
  answerId: string;
};

export type GetV3GfwTemplatesTemplateIdAnswersAnswerIdError = Fetcher.ErrorWrapper<undefined>;

export type GetV3GfwTemplatesTemplateIdAnswersAnswerIdVariables = {
  pathParams: GetV3GfwTemplatesTemplateIdAnswersAnswerIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Gets an answer with the given id
 */
export const fetchGetV3GfwTemplatesTemplateIdAnswersAnswerId = (
  variables: GetV3GfwTemplatesTemplateIdAnswersAnswerIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.AnswerResponse,
    GetV3GfwTemplatesTemplateIdAnswersAnswerIdError,
    undefined,
    {},
    {},
    GetV3GfwTemplatesTemplateIdAnswersAnswerIdPathParams
  >({ url: "/v3/gfw/templates/{templateId}/answers/{answerId}", method: "get", ...variables, signal });

/**
 * Gets an answer with the given id
 */
export const useGetV3GfwTemplatesTemplateIdAnswersAnswerId = <TData = Responses.AnswerResponse>(
  variables: GetV3GfwTemplatesTemplateIdAnswersAnswerIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.AnswerResponse, GetV3GfwTemplatesTemplateIdAnswersAnswerIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.AnswerResponse, GetV3GfwTemplatesTemplateIdAnswersAnswerIdError, TData>(
    queryKeyFn({
      path: "/v3/gfw/templates/{templateId}/answers/{answerId}",
      operationId: "getV3GfwTemplatesTemplateIdAnswersAnswerId",
      variables
    }),
    ({ signal }) => fetchGetV3GfwTemplatesTemplateIdAnswersAnswerId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DeleteV3GfwTemplatesTemplateIdAnswersAnswerIdPathParams = {
  templateId: string;
  answerId: string;
};

export type DeleteV3GfwTemplatesTemplateIdAnswersAnswerIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
>;

export type DeleteV3GfwTemplatesTemplateIdAnswersAnswerIdVariables = {
  pathParams: DeleteV3GfwTemplatesTemplateIdAnswersAnswerIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Deletes the answer with the given id
 */
export const fetchDeleteV3GfwTemplatesTemplateIdAnswersAnswerId = (
  variables: DeleteV3GfwTemplatesTemplateIdAnswersAnswerIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    undefined,
    DeleteV3GfwTemplatesTemplateIdAnswersAnswerIdError,
    undefined,
    {},
    {},
    DeleteV3GfwTemplatesTemplateIdAnswersAnswerIdPathParams
  >({ url: "/v3/gfw/templates/{templateId}/answers/{answerId}", method: "delete", ...variables, signal });

/**
 * Deletes the answer with the given id
 */
export const useDeleteV3GfwTemplatesTemplateIdAnswersAnswerId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteV3GfwTemplatesTemplateIdAnswersAnswerIdError,
      DeleteV3GfwTemplatesTemplateIdAnswersAnswerIdVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    undefined,
    DeleteV3GfwTemplatesTemplateIdAnswersAnswerIdError,
    DeleteV3GfwTemplatesTemplateIdAnswersAnswerIdVariables
  >(
    (variables: DeleteV3GfwTemplatesTemplateIdAnswersAnswerIdVariables) =>
      fetchDeleteV3GfwTemplatesTemplateIdAnswersAnswerId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type PatchV3GfwTemplatesTemplateIdAnswersAnswerIdPathParams = {
  templateId: string;
  answerId: string;
};

export type PatchV3GfwTemplatesTemplateIdAnswersAnswerIdError = Fetcher.ErrorWrapper<undefined>;

export type PatchV3GfwTemplatesTemplateIdAnswersAnswerIdRequestBody = {
  privateFiles?: string[];
  publicFiles?: string[];
};

export type PatchV3GfwTemplatesTemplateIdAnswersAnswerIdVariables = {
  body?: PatchV3GfwTemplatesTemplateIdAnswersAnswerIdRequestBody;
  pathParams: PatchV3GfwTemplatesTemplateIdAnswersAnswerIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Update file privacy for responses
 */
export const fetchPatchV3GfwTemplatesTemplateIdAnswersAnswerId = (
  variables: PatchV3GfwTemplatesTemplateIdAnswersAnswerIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    undefined,
    PatchV3GfwTemplatesTemplateIdAnswersAnswerIdError,
    PatchV3GfwTemplatesTemplateIdAnswersAnswerIdRequestBody,
    {},
    {},
    PatchV3GfwTemplatesTemplateIdAnswersAnswerIdPathParams
  >({ url: "/v3/gfw/templates/{templateId}/answers/{answerId}", method: "patch", ...variables, signal });

/**
 * Update file privacy for responses
 */
export const usePatchV3GfwTemplatesTemplateIdAnswersAnswerId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PatchV3GfwTemplatesTemplateIdAnswersAnswerIdError,
      PatchV3GfwTemplatesTemplateIdAnswersAnswerIdVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    undefined,
    PatchV3GfwTemplatesTemplateIdAnswersAnswerIdError,
    PatchV3GfwTemplatesTemplateIdAnswersAnswerIdVariables
  >(
    (variables: PatchV3GfwTemplatesTemplateIdAnswersAnswerIdVariables) =>
      fetchPatchV3GfwTemplatesTemplateIdAnswersAnswerId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetV3GfwTemplatesAllAnswersError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetV3GfwTemplatesAllAnswersVariables = CoreContext["fetcherOptions"];

/**
 * Gets all answers a user can view across all templates
 */
export const fetchGetV3GfwTemplatesAllAnswers = (
  variables: GetV3GfwTemplatesAllAnswersVariables,
  signal?: AbortSignal
) =>
  coreFetch<Responses.AnswersResponse, GetV3GfwTemplatesAllAnswersError, undefined, {}, {}, {}>({
    url: "/v3/gfw/templates/allAnswers",
    method: "get",
    ...variables,
    signal
  });

/**
 * Gets all answers a user can view across all templates
 */
export const useGetV3GfwTemplatesAllAnswers = <TData = Responses.AnswersResponse>(
  variables: GetV3GfwTemplatesAllAnswersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.AnswersResponse, GetV3GfwTemplatesAllAnswersError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.AnswersResponse, GetV3GfwTemplatesAllAnswersError, TData>(
    queryKeyFn({ path: "/v3/gfw/templates/allAnswers", operationId: "getV3GfwTemplatesAllAnswers", variables }),
    ({ signal }) => fetchGetV3GfwTemplatesAllAnswers({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DeleteV3GfwTemplatesAllAnswersError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type DeleteV3GfwTemplatesAllAnswersVariables = CoreContext["fetcherOptions"];

/**
 * Deletes all answers a user has created
 */
export const fetchDeleteV3GfwTemplatesAllAnswers = (
  variables: DeleteV3GfwTemplatesAllAnswersVariables,
  signal?: AbortSignal
) =>
  coreFetch<undefined, DeleteV3GfwTemplatesAllAnswersError, undefined, {}, {}, {}>({
    url: "/v3/gfw/templates/allAnswers",
    method: "delete",
    ...variables,
    signal
  });

/**
 * Deletes all answers a user has created
 */
export const useDeleteV3GfwTemplatesAllAnswers = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteV3GfwTemplatesAllAnswersError,
      DeleteV3GfwTemplatesAllAnswersVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    undefined,
    DeleteV3GfwTemplatesAllAnswersError,
    DeleteV3GfwTemplatesAllAnswersVariables
  >(
    (variables: DeleteV3GfwTemplatesAllAnswersVariables) =>
      fetchDeleteV3GfwTemplatesAllAnswers({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetV3GfwAreasUserError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
  | {
      status: 503;
      payload: Responses.Error;
    }
>;

export type GetV3GfwAreasUserVariables = CoreContext["fetcherOptions"];

/**
 * Gets all areas created by the user
 */
export const fetchGetV3GfwAreasUser = (variables: GetV3GfwAreasUserVariables, signal?: AbortSignal) =>
  coreFetch<Responses.AreasResponse, GetV3GfwAreasUserError, undefined, {}, {}, {}>({
    url: "/v3/gfw/areas/user",
    method: "get",
    ...variables,
    signal
  });

/**
 * Gets all areas created by the user
 */
export const useGetV3GfwAreasUser = <TData = Responses.AreasResponse>(
  variables: GetV3GfwAreasUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.AreasResponse, GetV3GfwAreasUserError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.AreasResponse, GetV3GfwAreasUserError, TData>(
    queryKeyFn({ path: "/v3/gfw/areas/user", operationId: "getV3GfwAreasUser", variables }),
    ({ signal }) => fetchGetV3GfwAreasUser({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwAreasUserandteamError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
  | {
      status: 503;
      payload: Responses.Error;
    }
>;

export type GetV3GfwAreasUserandteamVariables = CoreContext["fetcherOptions"];

/**
 * Gets all areas created by the user as well as areas linked to their teams
 */
export const fetchGetV3GfwAreasUserandteam = (variables: GetV3GfwAreasUserandteamVariables, signal?: AbortSignal) =>
  coreFetch<Responses.AreasResponse, GetV3GfwAreasUserandteamError, undefined, {}, {}, {}>({
    url: "/v3/gfw/areas/userAndTeam",
    method: "get",
    ...variables,
    signal
  });

/**
 * Gets all areas created by the user as well as areas linked to their teams
 */
export const useGetV3GfwAreasUserandteam = <TData = Responses.AreasResponse>(
  variables: GetV3GfwAreasUserandteamVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.AreasResponse, GetV3GfwAreasUserandteamError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.AreasResponse, GetV3GfwAreasUserandteamError, TData>(
    queryKeyFn({ path: "/v3/gfw/areas/userAndTeam", operationId: "getV3GfwAreasUserandteam", variables }),
    ({ signal }) => fetchGetV3GfwAreasUserandteam({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PostV3GfwAreasError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.Error;
    }
  | {
      status: 401;
      payload: Responses.Error;
    }
>;

export type PostV3GfwAreasVariables = {
  body?: RequestBodies.AreaBody;
} & CoreContext["fetcherOptions"];

/**
 * Creates a new area
 */
export const fetchPostV3GfwAreas = (variables: PostV3GfwAreasVariables, signal?: AbortSignal) =>
  coreFetch<Responses.AreaResponse, PostV3GfwAreasError, RequestBodies.AreaBody, {}, {}, {}>({
    url: "/v3/gfw/areas/",
    method: "post",
    ...variables,
    signal
  });

/**
 * Creates a new area
 */
export const usePostV3GfwAreas = (
  options?: Omit<
    reactQuery.UseMutationOptions<Responses.AreaResponse, PostV3GfwAreasError, PostV3GfwAreasVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<Responses.AreaResponse, PostV3GfwAreasError, PostV3GfwAreasVariables>(
    (variables: PostV3GfwAreasVariables) => fetchPostV3GfwAreas({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetV3GfwAreasAreaIdPathParams = {
  areaId: string;
};

export type GetV3GfwAreasAreaIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetV3GfwAreasAreaIdVariables = {
  pathParams: GetV3GfwAreasAreaIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Gets an area with the id provided
 */
export const fetchGetV3GfwAreasAreaId = (variables: GetV3GfwAreasAreaIdVariables, signal?: AbortSignal) =>
  coreFetch<Responses.AreaResponse, GetV3GfwAreasAreaIdError, undefined, {}, {}, GetV3GfwAreasAreaIdPathParams>({
    url: "/v3/gfw/areas/{areaId}",
    method: "get",
    ...variables,
    signal
  });

/**
 * Gets an area with the id provided
 */
export const useGetV3GfwAreasAreaId = <TData = Responses.AreaResponse>(
  variables: GetV3GfwAreasAreaIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.AreaResponse, GetV3GfwAreasAreaIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.AreaResponse, GetV3GfwAreasAreaIdError, TData>(
    queryKeyFn({ path: "/v3/gfw/areas/{areaId}", operationId: "getV3GfwAreasAreaId", variables }),
    ({ signal }) => fetchGetV3GfwAreasAreaId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PatchV3GfwAreasAreaIdPathParams = {
  areaId: string;
};

export type PatchV3GfwAreasAreaIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.Error;
    }
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type PatchV3GfwAreasAreaIdVariables = {
  body?: RequestBodies.AreaBody;
  pathParams: PatchV3GfwAreasAreaIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Updates an area with the given id
 */
export const fetchPatchV3GfwAreasAreaId = (variables: PatchV3GfwAreasAreaIdVariables, signal?: AbortSignal) =>
  coreFetch<
    Responses.AreaResponse,
    PatchV3GfwAreasAreaIdError,
    RequestBodies.AreaBody,
    {},
    {},
    PatchV3GfwAreasAreaIdPathParams
  >({ url: "/v3/gfw/areas/{areaId}", method: "patch", ...variables, signal });

/**
 * Updates an area with the given id
 */
export const usePatchV3GfwAreasAreaId = (
  options?: Omit<
    reactQuery.UseMutationOptions<Responses.AreaResponse, PatchV3GfwAreasAreaIdError, PatchV3GfwAreasAreaIdVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<Responses.AreaResponse, PatchV3GfwAreasAreaIdError, PatchV3GfwAreasAreaIdVariables>(
    (variables: PatchV3GfwAreasAreaIdVariables) => fetchPatchV3GfwAreasAreaId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DeleteV3GfwAreasAreaIdPathParams = {
  areaId: string;
};

export type DeleteV3GfwAreasAreaIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
>;

export type DeleteV3GfwAreasAreaIdVariables = {
  pathParams: DeleteV3GfwAreasAreaIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Deletes an area with the given id
 */
export const fetchDeleteV3GfwAreasAreaId = (variables: DeleteV3GfwAreasAreaIdVariables, signal?: AbortSignal) =>
  coreFetch<undefined, DeleteV3GfwAreasAreaIdError, undefined, {}, {}, DeleteV3GfwAreasAreaIdPathParams>({
    url: "/v3/gfw/areas/{areaId}",
    method: "delete",
    ...variables,
    signal
  });

/**
 * Deletes an area with the given id
 */
export const useDeleteV3GfwAreasAreaId = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteV3GfwAreasAreaIdError, DeleteV3GfwAreasAreaIdVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<undefined, DeleteV3GfwAreasAreaIdError, DeleteV3GfwAreasAreaIdVariables>(
    (variables: DeleteV3GfwAreasAreaIdVariables) => fetchDeleteV3GfwAreasAreaId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type PostV3GfwArearelationsTeamsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type PostV3GfwArearelationsTeamsVariables = {
  body?: RequestBodies.AreaTeamRelationBody;
} & CoreContext["fetcherOptions"];

/**
 * Creates an area-team relation
 */
export const fetchPostV3GfwArearelationsTeams = (
  variables: PostV3GfwArearelationsTeamsVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.TeamAreaRelationResponse,
    PostV3GfwArearelationsTeamsError,
    RequestBodies.AreaTeamRelationBody,
    {},
    {},
    {}
  >({ url: "/v3/gfw/arearelations/teams/", method: "post", ...variables, signal });

/**
 * Creates an area-team relation
 */
export const usePostV3GfwArearelationsTeams = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.TeamAreaRelationResponse,
      PostV3GfwArearelationsTeamsError,
      PostV3GfwArearelationsTeamsVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    Responses.TeamAreaRelationResponse,
    PostV3GfwArearelationsTeamsError,
    PostV3GfwArearelationsTeamsVariables
  >(
    (variables: PostV3GfwArearelationsTeamsVariables) =>
      fetchPostV3GfwArearelationsTeams({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DeleteV3GfwArearelationsTeamsError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type DeleteV3GfwArearelationsTeamsVariables = {
  body?: RequestBodies.AreaTeamRelationBody;
} & CoreContext["fetcherOptions"];

/**
 * Deletes an area-team relation
 */
export const fetchDeleteV3GfwArearelationsTeams = (
  variables: DeleteV3GfwArearelationsTeamsVariables,
  signal?: AbortSignal
) =>
  coreFetch<undefined, DeleteV3GfwArearelationsTeamsError, RequestBodies.AreaTeamRelationBody, {}, {}, {}>({
    url: "/v3/gfw/arearelations/teams/",
    method: "delete",
    ...variables,
    signal
  });

/**
 * Deletes an area-team relation
 */
export const useDeleteV3GfwArearelationsTeams = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteV3GfwArearelationsTeamsError,
      DeleteV3GfwArearelationsTeamsVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<undefined, DeleteV3GfwArearelationsTeamsError, DeleteV3GfwArearelationsTeamsVariables>(
    (variables: DeleteV3GfwArearelationsTeamsVariables) =>
      fetchDeleteV3GfwArearelationsTeams({ ...fetcherOptions, ...variables }),
    options
  );
};

export type PostV3GfwArearelationsTemplatesError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type PostV3GfwArearelationsTemplatesVariables = {
  body?: RequestBodies.AreaTemplateRelationBody;
} & CoreContext["fetcherOptions"];

/**
 * Creates an area-template relation
 */
export const fetchPostV3GfwArearelationsTemplates = (
  variables: PostV3GfwArearelationsTemplatesVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.AreaTemplateRelationResponse,
    PostV3GfwArearelationsTemplatesError,
    RequestBodies.AreaTemplateRelationBody,
    {},
    {},
    {}
  >({ url: "/v3/gfw/arearelations/templates", method: "post", ...variables, signal });

/**
 * Creates an area-template relation
 */
export const usePostV3GfwArearelationsTemplates = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.AreaTemplateRelationResponse,
      PostV3GfwArearelationsTemplatesError,
      PostV3GfwArearelationsTemplatesVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    Responses.AreaTemplateRelationResponse,
    PostV3GfwArearelationsTemplatesError,
    PostV3GfwArearelationsTemplatesVariables
  >(
    (variables: PostV3GfwArearelationsTemplatesVariables) =>
      fetchPostV3GfwArearelationsTemplates({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DeleteV3GfwArearelationsTemplatesError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type DeleteV3GfwArearelationsTemplatesVariables = {
  body?: RequestBodies.AreaTemplateRelationBody;
} & CoreContext["fetcherOptions"];

/**
 * Deletes an area-template relation
 */
export const fetchDeleteV3GfwArearelationsTemplates = (
  variables: DeleteV3GfwArearelationsTemplatesVariables,
  signal?: AbortSignal
) =>
  coreFetch<undefined, DeleteV3GfwArearelationsTemplatesError, RequestBodies.AreaTemplateRelationBody, {}, {}, {}>({
    url: "/v3/gfw/arearelations/templates",
    method: "delete",
    ...variables,
    signal
  });

/**
 * Deletes an area-template relation
 */
export const useDeleteV3GfwArearelationsTemplates = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteV3GfwArearelationsTemplatesError,
      DeleteV3GfwArearelationsTemplatesVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    undefined,
    DeleteV3GfwArearelationsTemplatesError,
    DeleteV3GfwArearelationsTemplatesVariables
  >(
    (variables: DeleteV3GfwArearelationsTemplatesVariables) =>
      fetchDeleteV3GfwArearelationsTemplates({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetV3GfwTeamsMyinvitesError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetV3GfwTeamsMyinvitesVariables = CoreContext["fetcherOptions"];

/**
 * Returns all team invites for the user
 */
export const fetchGetV3GfwTeamsMyinvites = (variables: GetV3GfwTeamsMyinvitesVariables, signal?: AbortSignal) =>
  coreFetch<Responses.TeamsResponse, GetV3GfwTeamsMyinvitesError, undefined, {}, {}, {}>({
    url: "/v3/gfw/teams/myinvites",
    method: "get",
    ...variables,
    signal
  });

/**
 * Returns all team invites for the user
 */
export const useGetV3GfwTeamsMyinvites = <TData = Responses.TeamsResponse>(
  variables: GetV3GfwTeamsMyinvitesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.TeamsResponse, GetV3GfwTeamsMyinvitesError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.TeamsResponse, GetV3GfwTeamsMyinvitesError, TData>(
    queryKeyFn({ path: "/v3/gfw/teams/myinvites", operationId: "getV3GfwTeamsMyinvites", variables }),
    ({ signal }) => fetchGetV3GfwTeamsMyinvites({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwTeamsUserUserIdPathParams = {
  userId: string;
};

export type GetV3GfwTeamsUserUserIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetV3GfwTeamsUserUserIdVariables = {
  pathParams: GetV3GfwTeamsUserUserIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Returns an array of all teams the user is member of
 */
export const fetchGetV3GfwTeamsUserUserId = (variables: GetV3GfwTeamsUserUserIdVariables, signal?: AbortSignal) =>
  coreFetch<
    Responses.TeamsResponse,
    GetV3GfwTeamsUserUserIdError,
    undefined,
    {},
    {},
    GetV3GfwTeamsUserUserIdPathParams
  >({ url: "/v3/gfw/teams/user/{userId}", method: "get", ...variables, signal });

/**
 * Returns an array of all teams the user is member of
 */
export const useGetV3GfwTeamsUserUserId = <TData = Responses.TeamsResponse>(
  variables: GetV3GfwTeamsUserUserIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.TeamsResponse, GetV3GfwTeamsUserUserIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.TeamsResponse, GetV3GfwTeamsUserUserIdError, TData>(
    queryKeyFn({ path: "/v3/gfw/teams/user/{userId}", operationId: "getV3GfwTeamsUserUserId", variables }),
    ({ signal }) => fetchGetV3GfwTeamsUserUserId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwTeamsTeamIdPathParams = {
  teamId: string;
};

export type GetV3GfwTeamsTeamIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetV3GfwTeamsTeamIdVariables = {
  pathParams: GetV3GfwTeamsTeamIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Gets a teams details
 */
export const fetchGetV3GfwTeamsTeamId = (variables: GetV3GfwTeamsTeamIdVariables, signal?: AbortSignal) =>
  coreFetch<Responses.TeamResponse, GetV3GfwTeamsTeamIdError, undefined, {}, {}, GetV3GfwTeamsTeamIdPathParams>({
    url: "/v3/gfw/teams/{teamId}",
    method: "get",
    ...variables,
    signal
  });

/**
 * Gets a teams details
 */
export const useGetV3GfwTeamsTeamId = <TData = Responses.TeamResponse>(
  variables: GetV3GfwTeamsTeamIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.TeamResponse, GetV3GfwTeamsTeamIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.TeamResponse, GetV3GfwTeamsTeamIdError, TData>(
    queryKeyFn({ path: "/v3/gfw/teams/{teamId}", operationId: "getV3GfwTeamsTeamId", variables }),
    ({ signal }) => fetchGetV3GfwTeamsTeamId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PatchV3GfwTeamsTeamIdPathParams = {
  teamId: string;
};

export type PatchV3GfwTeamsTeamIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type PatchV3GfwTeamsTeamIdVariables = {
  body: RequestBodies.TeamBody;
  pathParams: PatchV3GfwTeamsTeamIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Updates the name of the team
 */
export const fetchPatchV3GfwTeamsTeamId = (variables: PatchV3GfwTeamsTeamIdVariables, signal?: AbortSignal) =>
  coreFetch<
    Responses.TeamResponse,
    PatchV3GfwTeamsTeamIdError,
    RequestBodies.TeamBody,
    {},
    {},
    PatchV3GfwTeamsTeamIdPathParams
  >({ url: "/v3/gfw/teams/{teamId}", method: "patch", ...variables, signal });

/**
 * Updates the name of the team
 */
export const usePatchV3GfwTeamsTeamId = (
  options?: Omit<
    reactQuery.UseMutationOptions<Responses.TeamResponse, PatchV3GfwTeamsTeamIdError, PatchV3GfwTeamsTeamIdVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<Responses.TeamResponse, PatchV3GfwTeamsTeamIdError, PatchV3GfwTeamsTeamIdVariables>(
    (variables: PatchV3GfwTeamsTeamIdVariables) => fetchPatchV3GfwTeamsTeamId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DeleteV3GfwTeamsTeamIdPathParams = {
  teamId: string;
};

export type DeleteV3GfwTeamsTeamIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type DeleteV3GfwTeamsTeamIdVariables = {
  pathParams: DeleteV3GfwTeamsTeamIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Deletes a team and all team members
 */
export const fetchDeleteV3GfwTeamsTeamId = (variables: DeleteV3GfwTeamsTeamIdVariables, signal?: AbortSignal) =>
  coreFetch<undefined, DeleteV3GfwTeamsTeamIdError, undefined, {}, {}, DeleteV3GfwTeamsTeamIdPathParams>({
    url: "/v3/gfw/teams/{teamId}",
    method: "delete",
    ...variables,
    signal
  });

/**
 * Deletes a team and all team members
 */
export const useDeleteV3GfwTeamsTeamId = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteV3GfwTeamsTeamIdError, DeleteV3GfwTeamsTeamIdVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<undefined, DeleteV3GfwTeamsTeamIdError, DeleteV3GfwTeamsTeamIdVariables>(
    (variables: DeleteV3GfwTeamsTeamIdVariables) => fetchDeleteV3GfwTeamsTeamId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type PostV3GfwTeamsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.Error;
    }
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type PostV3GfwTeamsVariables = {
  body: RequestBodies.TeamBody;
} & CoreContext["fetcherOptions"];

/**
 * Creates a new team and makes the current user the team admin
 */
export const fetchPostV3GfwTeams = (variables: PostV3GfwTeamsVariables, signal?: AbortSignal) =>
  coreFetch<Responses.TeamResponse, PostV3GfwTeamsError, RequestBodies.TeamBody, {}, {}, {}>({
    url: "/v3/gfw/teams",
    method: "post",
    ...variables,
    signal
  });

/**
 * Creates a new team and makes the current user the team admin
 */
export const usePostV3GfwTeams = (
  options?: Omit<
    reactQuery.UseMutationOptions<Responses.TeamResponse, PostV3GfwTeamsError, PostV3GfwTeamsVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<Responses.TeamResponse, PostV3GfwTeamsError, PostV3GfwTeamsVariables>(
    (variables: PostV3GfwTeamsVariables) => fetchPostV3GfwTeams({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetV3GfwTeamsTeamIdUsersPathParams = {
  teamId: string;
};

export type GetV3GfwTeamsTeamIdUsersError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetV3GfwTeamsTeamIdUsersVariables = {
  pathParams: GetV3GfwTeamsTeamIdUsersPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Returns the members of the team of the given id
 */
export const fetchGetV3GfwTeamsTeamIdUsers = (variables: GetV3GfwTeamsTeamIdUsersVariables, signal?: AbortSignal) =>
  coreFetch<
    Responses.TeamMembersResponse,
    GetV3GfwTeamsTeamIdUsersError,
    undefined,
    {},
    {},
    GetV3GfwTeamsTeamIdUsersPathParams
  >({ url: "/v3/gfw/teams/{teamId}/users", method: "get", ...variables, signal });

/**
 * Returns the members of the team of the given id
 */
export const useGetV3GfwTeamsTeamIdUsers = <TData = Responses.TeamMembersResponse>(
  variables: GetV3GfwTeamsTeamIdUsersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.TeamMembersResponse, GetV3GfwTeamsTeamIdUsersError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.TeamMembersResponse, GetV3GfwTeamsTeamIdUsersError, TData>(
    queryKeyFn({ path: "/v3/gfw/teams/{teamId}/users", operationId: "getV3GfwTeamsTeamIdUsers", variables }),
    ({ signal }) => fetchGetV3GfwTeamsTeamIdUsers({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PostV3GfwTeamsTeamIdUsersPathParams = {
  teamId: string;
};

export type PostV3GfwTeamsTeamIdUsersError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.Error;
    }
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type PostV3GfwTeamsTeamIdUsersRequestBody = {
  email: string;
  role: "manager" | "monitor";
}[];

export type PostV3GfwTeamsTeamIdUsersVariables = {
  body?: PostV3GfwTeamsTeamIdUsersRequestBody;
  pathParams: PostV3GfwTeamsTeamIdUsersPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Adds one or more team members
 */
export const fetchPostV3GfwTeamsTeamIdUsers = (variables: PostV3GfwTeamsTeamIdUsersVariables, signal?: AbortSignal) =>
  coreFetch<
    Responses.TeamMembersResponse,
    PostV3GfwTeamsTeamIdUsersError,
    PostV3GfwTeamsTeamIdUsersRequestBody,
    {},
    {},
    PostV3GfwTeamsTeamIdUsersPathParams
  >({ url: "/v3/gfw/teams/{teamId}/users", method: "post", ...variables, signal });

/**
 * Adds one or more team members
 */
export const usePostV3GfwTeamsTeamIdUsers = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.TeamMembersResponse,
      PostV3GfwTeamsTeamIdUsersError,
      PostV3GfwTeamsTeamIdUsersVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    Responses.TeamMembersResponse,
    PostV3GfwTeamsTeamIdUsersError,
    PostV3GfwTeamsTeamIdUsersVariables
  >(
    (variables: PostV3GfwTeamsTeamIdUsersVariables) =>
      fetchPostV3GfwTeamsTeamIdUsers({ ...fetcherOptions, ...variables }),
    options
  );
};

export type PatchV3GfwTeamsTeamIdUsersReassignAdminUserIdPathParams = {
  teamId: string;
  userId: string;
};

export type PatchV3GfwTeamsTeamIdUsersReassignAdminUserIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type PatchV3GfwTeamsTeamIdUsersReassignAdminUserIdVariables = {
  pathParams: PatchV3GfwTeamsTeamIdUsersReassignAdminUserIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Reassigns the team admin to the user with the supplied id
 */
export const fetchPatchV3GfwTeamsTeamIdUsersReassignAdminUserId = (
  variables: PatchV3GfwTeamsTeamIdUsersReassignAdminUserIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.TeamMemberResponse,
    PatchV3GfwTeamsTeamIdUsersReassignAdminUserIdError,
    undefined,
    {},
    {},
    PatchV3GfwTeamsTeamIdUsersReassignAdminUserIdPathParams
  >({ url: "/v3/gfw/teams/{teamId}/users/reassignAdmin/{userId}", method: "patch", ...variables, signal });

/**
 * Reassigns the team admin to the user with the supplied id
 */
export const usePatchV3GfwTeamsTeamIdUsersReassignAdminUserId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.TeamMemberResponse,
      PatchV3GfwTeamsTeamIdUsersReassignAdminUserIdError,
      PatchV3GfwTeamsTeamIdUsersReassignAdminUserIdVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    Responses.TeamMemberResponse,
    PatchV3GfwTeamsTeamIdUsersReassignAdminUserIdError,
    PatchV3GfwTeamsTeamIdUsersReassignAdminUserIdVariables
  >(
    (variables: PatchV3GfwTeamsTeamIdUsersReassignAdminUserIdVariables) =>
      fetchPatchV3GfwTeamsTeamIdUsersReassignAdminUserId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdPathParams = {
  teamId: string;
  teamMemberRelationId: string;
};

export type PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.Error;
    }
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdRequestBody = {
  role?: "manager" | "monitor";
};

export type PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdVariables = {
  body?: PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdRequestBody;
  pathParams: PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Updates the team member's role. The teamMemberRelationId is the id supplied in a team members record, not the id of the user.
 */
export const fetchPatchV3GfwTeamsTeamIdUsersTeamMemberRelationId = (
  variables: PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.TeamMemberResponse,
    PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdError,
    PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdRequestBody,
    {},
    {},
    PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdPathParams
  >({ url: "/v3/gfw/teams/{teamId}/users/{teamMemberRelationId}", method: "patch", ...variables, signal });

/**
 * Updates the team member's role. The teamMemberRelationId is the id supplied in a team members record, not the id of the user.
 */
export const usePatchV3GfwTeamsTeamIdUsersTeamMemberRelationId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.TeamMemberResponse,
      PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdError,
      PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    Responses.TeamMemberResponse,
    PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdError,
    PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdVariables
  >(
    (variables: PatchV3GfwTeamsTeamIdUsersTeamMemberRelationIdVariables) =>
      fetchPatchV3GfwTeamsTeamIdUsersTeamMemberRelationId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DeleteV3GfwTeamsTeamIdUsersTeamMemberRelationIdPathParams = {
  teamId: string;
  teamMemberRelationId: string;
};

export type DeleteV3GfwTeamsTeamIdUsersTeamMemberRelationIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.Error;
    }
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type DeleteV3GfwTeamsTeamIdUsersTeamMemberRelationIdVariables = {
  pathParams: DeleteV3GfwTeamsTeamIdUsersTeamMemberRelationIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Removed the team member from the team. The teamMemberRelationId is the id supplied in a team members record, not the id of the user.
 */
export const fetchDeleteV3GfwTeamsTeamIdUsersTeamMemberRelationId = (
  variables: DeleteV3GfwTeamsTeamIdUsersTeamMemberRelationIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    undefined,
    DeleteV3GfwTeamsTeamIdUsersTeamMemberRelationIdError,
    undefined,
    {},
    {},
    DeleteV3GfwTeamsTeamIdUsersTeamMemberRelationIdPathParams
  >({ url: "/v3/gfw/teams/{teamId}/users/{teamMemberRelationId}", method: "delete", ...variables, signal });

/**
 * Removed the team member from the team. The teamMemberRelationId is the id supplied in a team members record, not the id of the user.
 */
export const useDeleteV3GfwTeamsTeamIdUsersTeamMemberRelationId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteV3GfwTeamsTeamIdUsersTeamMemberRelationIdError,
      DeleteV3GfwTeamsTeamIdUsersTeamMemberRelationIdVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    undefined,
    DeleteV3GfwTeamsTeamIdUsersTeamMemberRelationIdError,
    DeleteV3GfwTeamsTeamIdUsersTeamMemberRelationIdVariables
  >(
    (variables: DeleteV3GfwTeamsTeamIdUsersTeamMemberRelationIdVariables) =>
      fetchDeleteV3GfwTeamsTeamIdUsersTeamMemberRelationId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type PatchV3GfwTeamsTeamIdUsersUserIdAcceptPathParams = {
  teamId: string;
  userId: string;
};

export type PatchV3GfwTeamsTeamIdUsersUserIdAcceptError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
>;

export type PatchV3GfwTeamsTeamIdUsersUserIdAcceptVariables = {
  pathParams: PatchV3GfwTeamsTeamIdUsersUserIdAcceptPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Accepts an invitation to join team with id, for user with id
 */
export const fetchPatchV3GfwTeamsTeamIdUsersUserIdAccept = (
  variables: PatchV3GfwTeamsTeamIdUsersUserIdAcceptVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.TeamMemberResponse,
    PatchV3GfwTeamsTeamIdUsersUserIdAcceptError,
    undefined,
    {},
    {},
    PatchV3GfwTeamsTeamIdUsersUserIdAcceptPathParams
  >({ url: "/v3/gfw/teams/{teamId}/users/{userId}/accept", method: "patch", ...variables, signal });

/**
 * Accepts an invitation to join team with id, for user with id
 */
export const usePatchV3GfwTeamsTeamIdUsersUserIdAccept = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.TeamMemberResponse,
      PatchV3GfwTeamsTeamIdUsersUserIdAcceptError,
      PatchV3GfwTeamsTeamIdUsersUserIdAcceptVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    Responses.TeamMemberResponse,
    PatchV3GfwTeamsTeamIdUsersUserIdAcceptError,
    PatchV3GfwTeamsTeamIdUsersUserIdAcceptVariables
  >(
    (variables: PatchV3GfwTeamsTeamIdUsersUserIdAcceptVariables) =>
      fetchPatchV3GfwTeamsTeamIdUsersUserIdAccept({ ...fetcherOptions, ...variables }),
    options
  );
};

export type PatchV3GfwTeamsTeamIdUsersUserIdDeclinePathParams = {
  teamId: string;
  userId: string;
};

export type PatchV3GfwTeamsTeamIdUsersUserIdDeclineError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
>;

export type PatchV3GfwTeamsTeamIdUsersUserIdDeclineVariables = {
  pathParams: PatchV3GfwTeamsTeamIdUsersUserIdDeclinePathParams;
} & CoreContext["fetcherOptions"];

/**
 * Declines an invitation to join team with id, for user with id
 */
export const fetchPatchV3GfwTeamsTeamIdUsersUserIdDecline = (
  variables: PatchV3GfwTeamsTeamIdUsersUserIdDeclineVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.TeamMemberResponse,
    PatchV3GfwTeamsTeamIdUsersUserIdDeclineError,
    undefined,
    {},
    {},
    PatchV3GfwTeamsTeamIdUsersUserIdDeclinePathParams
  >({ url: "/v3/gfw/teams/{teamId}/users/{userId}/decline", method: "patch", ...variables, signal });

/**
 * Declines an invitation to join team with id, for user with id
 */
export const usePatchV3GfwTeamsTeamIdUsersUserIdDecline = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.TeamMemberResponse,
      PatchV3GfwTeamsTeamIdUsersUserIdDeclineError,
      PatchV3GfwTeamsTeamIdUsersUserIdDeclineVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    Responses.TeamMemberResponse,
    PatchV3GfwTeamsTeamIdUsersUserIdDeclineError,
    PatchV3GfwTeamsTeamIdUsersUserIdDeclineVariables
  >(
    (variables: PatchV3GfwTeamsTeamIdUsersUserIdDeclineVariables) =>
      fetchPatchV3GfwTeamsTeamIdUsersUserIdDecline({ ...fetcherOptions, ...variables }),
    options
  );
};

export type PatchV3GfwTeamsTeamIdUsersUserIdLeavePathParams = {
  teamId: string;
  userId: string;
};

export type PatchV3GfwTeamsTeamIdUsersUserIdLeaveError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.Error;
    }
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 403;
      payload: Responses.Error;
    }
>;

export type PatchV3GfwTeamsTeamIdUsersUserIdLeaveVariables = {
  pathParams: PatchV3GfwTeamsTeamIdUsersUserIdLeavePathParams;
} & CoreContext["fetcherOptions"];

/**
 * Leaves the team with id, for user with id
 */
export const fetchPatchV3GfwTeamsTeamIdUsersUserIdLeave = (
  variables: PatchV3GfwTeamsTeamIdUsersUserIdLeaveVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.TeamMemberResponse,
    PatchV3GfwTeamsTeamIdUsersUserIdLeaveError,
    undefined,
    {},
    {},
    PatchV3GfwTeamsTeamIdUsersUserIdLeavePathParams
  >({ url: "/v3/gfw/teams/{teamId}/users/{userId}/leave", method: "patch", ...variables, signal });

/**
 * Leaves the team with id, for user with id
 */
export const usePatchV3GfwTeamsTeamIdUsersUserIdLeave = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.TeamMemberResponse,
      PatchV3GfwTeamsTeamIdUsersUserIdLeaveError,
      PatchV3GfwTeamsTeamIdUsersUserIdLeaveVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    Responses.TeamMemberResponse,
    PatchV3GfwTeamsTeamIdUsersUserIdLeaveError,
    PatchV3GfwTeamsTeamIdUsersUserIdLeaveVariables
  >(
    (variables: PatchV3GfwTeamsTeamIdUsersUserIdLeaveVariables) =>
      fetchPatchV3GfwTeamsTeamIdUsersUserIdLeave({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetV3GfwAssignmentsAllOpenUserForAreaAreaIdPathParams = {
  areaId: string;
};

export type GetV3GfwAssignmentsAllOpenUserForAreaAreaIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetV3GfwAssignmentsAllOpenUserForAreaAreaIdVariables = {
  pathParams: GetV3GfwAssignmentsAllOpenUserForAreaAreaIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Returns all assignments the user has created or is assigned to for a given area
 */
export const fetchGetV3GfwAssignmentsAllOpenUserForAreaAreaId = (
  variables: GetV3GfwAssignmentsAllOpenUserForAreaAreaIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.AssignmentsResponse,
    GetV3GfwAssignmentsAllOpenUserForAreaAreaIdError,
    undefined,
    {},
    {},
    GetV3GfwAssignmentsAllOpenUserForAreaAreaIdPathParams
  >({ url: "/v3/gfw/assignments/allOpenUserForArea/{areaId}", method: "get", ...variables, signal });

/**
 * Returns all assignments the user has created or is assigned to for a given area
 */
export const useGetV3GfwAssignmentsAllOpenUserForAreaAreaId = <TData = Responses.AssignmentsResponse>(
  variables: GetV3GfwAssignmentsAllOpenUserForAreaAreaIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.AssignmentsResponse, GetV3GfwAssignmentsAllOpenUserForAreaAreaIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.AssignmentsResponse, GetV3GfwAssignmentsAllOpenUserForAreaAreaIdError, TData>(
    queryKeyFn({
      path: "/v3/gfw/assignments/allOpenUserForArea/{areaId}",
      operationId: "getV3GfwAssignmentsAllOpenUserForAreaAreaId",
      variables
    }),
    ({ signal }) => fetchGetV3GfwAssignmentsAllOpenUserForAreaAreaId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwTemplatesVersionsIdLatestPathParams = {
  /**
   * Edit group id for which the latest version is to be fetched
   */
  editGroupId: string;
};

export type GetV3GfwTemplatesVersionsIdLatestError = Fetcher.ErrorWrapper<undefined>;

export type GetV3GfwTemplatesVersionsIdLatestVariables = {
  pathParams: GetV3GfwTemplatesVersionsIdLatestPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Fetches the latest version in a given template group
 */
export const fetchGetV3GfwTemplatesVersionsIdLatest = (
  variables: GetV3GfwTemplatesVersionsIdLatestVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.AssignmentResponse,
    GetV3GfwTemplatesVersionsIdLatestError,
    undefined,
    {},
    {},
    GetV3GfwTemplatesVersionsIdLatestPathParams
  >({ url: "/v3/gfw/templates/versions/{editGroupId}/latest", method: "get", ...variables, signal });

/**
 * Fetches the latest version in a given template group
 */
export const useGetV3GfwTemplatesVersionsIdLatest = <TData = Responses.AssignmentResponse>(
  variables: GetV3GfwTemplatesVersionsIdLatestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.AssignmentResponse, GetV3GfwTemplatesVersionsIdLatestError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.AssignmentResponse, GetV3GfwTemplatesVersionsIdLatestError, TData>(
    queryKeyFn({
      path: "/v3/gfw/templates/versions/{editGroupId}/latest",
      operationId: "getV3GfwTemplatesVersionsIdLatest",
      variables
    }),
    ({ signal }) => fetchGetV3GfwTemplatesVersionsIdLatest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwTemplatesLatestError = Fetcher.ErrorWrapper<undefined>;

export type GetV3GfwTemplatesLatestVariables = CoreContext["fetcherOptions"];

/**
 * Returns a list of all user templates with the latest version of each template. The answer count refers to the sum of the answers for the edit group the template is in.
 */
export const fetchGetV3GfwTemplatesLatest = (variables: GetV3GfwTemplatesLatestVariables, signal?: AbortSignal) =>
  coreFetch<Responses.TemplatesResponse, GetV3GfwTemplatesLatestError, undefined, {}, {}, {}>({
    url: "/v3/gfw/templates/latest",
    method: "get",
    ...variables,
    signal
  });

/**
 * Returns a list of all user templates with the latest version of each template. The answer count refers to the sum of the answers for the edit group the template is in.
 */
export const useGetV3GfwTemplatesLatest = <TData = Responses.TemplatesResponse>(
  variables: GetV3GfwTemplatesLatestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.TemplatesResponse, GetV3GfwTemplatesLatestError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.TemplatesResponse, GetV3GfwTemplatesLatestError, TData>(
    queryKeyFn({ path: "/v3/gfw/templates/latest", operationId: "getV3GfwTemplatesLatest", variables }),
    ({ signal }) => fetchGetV3GfwTemplatesLatest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwTemplatesVersionEditGroupIdPathParams = {
  /**
   * The edit group id for the template group
   */
  editGroupId: string;
};

export type GetV3GfwTemplatesVersionEditGroupIdError = Fetcher.ErrorWrapper<undefined>;

export type GetV3GfwTemplatesVersionEditGroupIdVariables = {
  pathParams: GetV3GfwTemplatesVersionEditGroupIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Get a list of templates which are all the versions for the given edit group id. The answer counts on each templates refer to the answer count per individual version.
 */
export const fetchGetV3GfwTemplatesVersionEditGroupId = (
  variables: GetV3GfwTemplatesVersionEditGroupIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.TemplatesResponse,
    GetV3GfwTemplatesVersionEditGroupIdError,
    undefined,
    {},
    {},
    GetV3GfwTemplatesVersionEditGroupIdPathParams
  >({ url: "/v3/gfw/templates/versions/{editGroupId}", method: "get", ...variables, signal });

/**
 * Get a list of templates which are all the versions for the given edit group id. The answer counts on each templates refer to the answer count per individual version.
 */
export const useGetV3GfwTemplatesVersionEditGroupId = <TData = Responses.TemplatesResponse>(
  variables: GetV3GfwTemplatesVersionEditGroupIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.TemplatesResponse, GetV3GfwTemplatesVersionEditGroupIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.TemplatesResponse, GetV3GfwTemplatesVersionEditGroupIdError, TData>(
    queryKeyFn({
      path: "/v3/gfw/templates/versions/{editGroupId}",
      operationId: "getV3GfwTemplatesVersionEditGroupId",
      variables
    }),
    ({ signal }) => fetchGetV3GfwTemplatesVersionEditGroupId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PatchV3TemplatesTemplateIdStatusPathParams = {
  /**
   * Id for the template to be updated
   */
  templateId: string;
};

export type PatchV3TemplatesTemplateIdStatusError = Fetcher.ErrorWrapper<undefined>;

export type PatchV3TemplatesTemplateIdStatusRequestBody = {
  status: "published" | "unpublished";
};

export type PatchV3TemplatesTemplateIdStatusVariables = {
  body: PatchV3TemplatesTemplateIdStatusRequestBody;
  pathParams: PatchV3TemplatesTemplateIdStatusPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Update the status of a given template
 */
export const fetchPatchV3TemplatesTemplateIdStatus = (
  variables: PatchV3TemplatesTemplateIdStatusVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.TemplateResponse,
    PatchV3TemplatesTemplateIdStatusError,
    PatchV3TemplatesTemplateIdStatusRequestBody,
    {},
    {},
    PatchV3TemplatesTemplateIdStatusPathParams
  >({ url: "/v3/gfw/templates/{templateId}/status", method: "patch", ...variables, signal });

/**
 * Update the status of a given template
 */
export const usePatchV3TemplatesTemplateIdStatus = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.TemplateResponse,
      PatchV3TemplatesTemplateIdStatusError,
      PatchV3TemplatesTemplateIdStatusVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    Responses.TemplateResponse,
    PatchV3TemplatesTemplateIdStatusError,
    PatchV3TemplatesTemplateIdStatusVariables
  >(
    (variables: PatchV3TemplatesTemplateIdStatusVariables) =>
      fetchPatchV3TemplatesTemplateIdStatus({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetV3GfwTemplatesPublicError = Fetcher.ErrorWrapper<undefined>;

export type GetV3GfwTemplatesPublicVariables = CoreContext["fetcherOptions"];

/**
 * Returns a list of all public templates
 */
export const fetchGetV3GfwTemplatesPublic = (variables: GetV3GfwTemplatesPublicVariables, signal?: AbortSignal) =>
  coreFetch<Responses.TemplatesResponse, GetV3GfwTemplatesPublicError, undefined, {}, {}, {}>({
    url: "/v3/gfw/templates/public",
    method: "get",
    ...variables,
    signal
  });

/**
 * Returns a list of all public templates
 */
export const useGetV3GfwTemplatesPublic = <TData = Responses.TemplatesResponse>(
  variables: GetV3GfwTemplatesPublicVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.TemplatesResponse, GetV3GfwTemplatesPublicError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.TemplatesResponse, GetV3GfwTemplatesPublicError, TData>(
    queryKeyFn({ path: "/v3/gfw/templates/public", operationId: "getV3GfwTemplatesPublic", variables }),
    ({ signal }) => fetchGetV3GfwTemplatesPublic({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwAreaTeamsAreaIdPathParams = {
  areaId: string;
};

export type GetV3GfwAreaTeamsAreaIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetV3GfwAreaTeamsAreaIdVariables = {
  pathParams: GetV3GfwAreaTeamsAreaIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Returns an array of team ids associated with the area
 */
export const fetchGetV3GfwAreaTeamsAreaId = (variables: GetV3GfwAreaTeamsAreaIdVariables, signal?: AbortSignal) =>
  coreFetch<
    Responses.StringArrayResponse,
    GetV3GfwAreaTeamsAreaIdError,
    undefined,
    {},
    {},
    GetV3GfwAreaTeamsAreaIdPathParams
  >({ url: "/v3/gfw/arearelations/teams/areaTeams/{areaId}", method: "get", ...variables, signal });

/**
 * Returns an array of team ids associated with the area
 */
export const useGetV3GfwAreaTeamsAreaId = <TData = Responses.StringArrayResponse>(
  variables: GetV3GfwAreaTeamsAreaIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.StringArrayResponse, GetV3GfwAreaTeamsAreaIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.StringArrayResponse, GetV3GfwAreaTeamsAreaIdError, TData>(
    queryKeyFn({
      path: "/v3/gfw/arearelations/teams/areaTeams/{areaId}",
      operationId: "getV3GfwAreaTeamsAreaId",
      variables
    }),
    ({ signal }) => fetchGetV3GfwAreaTeamsAreaId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwArearelationsTeamsTeamAreasTeamIdPathParams = {
  teamId: string;
};

export type GetV3GfwArearelationsTeamsTeamAreasTeamIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetV3GfwArearelationsTeamsTeamAreasTeamIdVariables = {
  pathParams: GetV3GfwArearelationsTeamsTeamAreasTeamIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Returns an array of area ids relating to a team
 */
export const fetchGetV3GfwArearelationsTeamsTeamAreasTeamId = (
  variables: GetV3GfwArearelationsTeamsTeamAreasTeamIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.StringArrayResponse,
    GetV3GfwArearelationsTeamsTeamAreasTeamIdError,
    undefined,
    {},
    {},
    GetV3GfwArearelationsTeamsTeamAreasTeamIdPathParams
  >({ url: "/v3/gfw/arearelations/teams/teamAreas/{teamId}", method: "get", ...variables, signal });

/**
 * Returns an array of area ids relating to a team
 */
export const useGetV3GfwArearelationsTeamsTeamAreasTeamId = <TData = Responses.StringArrayResponse>(
  variables: GetV3GfwArearelationsTeamsTeamAreasTeamIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.StringArrayResponse, GetV3GfwArearelationsTeamsTeamAreasTeamIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.StringArrayResponse, GetV3GfwArearelationsTeamsTeamAreasTeamIdError, TData>(
    queryKeyFn({
      path: "/v3/gfw/arearelations/teams/teamAreas/{teamId}",
      operationId: "getV3GfwArearelationsTeamsTeamAreasTeamId",
      variables
    }),
    ({ signal }) => fetchGetV3GfwArearelationsTeamsTeamAreasTeamId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwArearelationsTemplatesAreaTemplatesAreaIdPathParams = {
  areaId: string;
};

export type GetV3GfwArearelationsTemplatesAreaTemplatesAreaIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.StringArrayResponse;
    }
>;

export type GetV3GfwArearelationsTemplatesAreaTemplatesAreaIdVariables = {
  pathParams: GetV3GfwArearelationsTemplatesAreaTemplatesAreaIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Returns an array of template ids related to an area
 */
export const fetchGetV3GfwArearelationsTemplatesAreaTemplatesAreaId = (
  variables: GetV3GfwArearelationsTemplatesAreaTemplatesAreaIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.StringArrayResponse,
    GetV3GfwArearelationsTemplatesAreaTemplatesAreaIdError,
    undefined,
    {},
    {},
    GetV3GfwArearelationsTemplatesAreaTemplatesAreaIdPathParams
  >({ url: "/v3/gfw/arearelations/templates/areaTemplates/{areaId}", method: "get", ...variables, signal });

/**
 * Returns an array of template ids related to an area
 */
export const useGetV3GfwArearelationsTemplatesAreaTemplatesAreaId = <TData = Responses.StringArrayResponse>(
  variables: GetV3GfwArearelationsTemplatesAreaTemplatesAreaIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Responses.StringArrayResponse,
      GetV3GfwArearelationsTemplatesAreaTemplatesAreaIdError,
      TData
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<
    Responses.StringArrayResponse,
    GetV3GfwArearelationsTemplatesAreaTemplatesAreaIdError,
    TData
  >(
    queryKeyFn({
      path: "/v3/gfw/arearelations/templates/areaTemplates/{areaId}",
      operationId: "getV3GfwArearelationsTemplatesAreaTemplatesAreaId",
      variables
    }),
    ({ signal }) => fetchGetV3GfwArearelationsTemplatesAreaTemplatesAreaId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GetV3GfwArearelationsTemplatesTemplateAreasTemplateIdPathParams = {
  templateId: string;
};

export type GetV3GfwArearelationsTemplatesTemplateAreasTemplateIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.Error;
    }
  | {
      status: 404;
      payload: Responses.Error;
    }
>;

export type GetV3GfwArearelationsTemplatesTemplateAreasTemplateIdVariables = {
  pathParams: GetV3GfwArearelationsTemplatesTemplateAreasTemplateIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Returns an array of area ids related to a template
 */
export const fetchGetV3GfwArearelationsTemplatesTemplateAreasTemplateId = (
  variables: GetV3GfwArearelationsTemplatesTemplateAreasTemplateIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.StringArrayResponse,
    GetV3GfwArearelationsTemplatesTemplateAreasTemplateIdError,
    undefined,
    {},
    {},
    GetV3GfwArearelationsTemplatesTemplateAreasTemplateIdPathParams
  >({ url: "/v3/gfw/arearelations/templates/templateAreas/{templateId}", method: "get", ...variables, signal });

/**
 * Returns an array of area ids related to a template
 */
export const useGetV3GfwArearelationsTemplatesTemplateAreasTemplateId = <TData = Responses.StringArrayResponse>(
  variables: GetV3GfwArearelationsTemplatesTemplateAreasTemplateIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Responses.StringArrayResponse,
      GetV3GfwArearelationsTemplatesTemplateAreasTemplateIdError,
      TData
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<
    Responses.StringArrayResponse,
    GetV3GfwArearelationsTemplatesTemplateAreasTemplateIdError,
    TData
  >(
    queryKeyFn({
      path: "/v3/gfw/arearelations/templates/templateAreas/{templateId}",
      operationId: "getV3GfwArearelationsTemplatesTemplateAreasTemplateId",
      variables
    }),
    ({ signal }) =>
      fetchGetV3GfwArearelationsTemplatesTemplateAreasTemplateId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PatchV3GfwAssignmentsAssignmentIdStatusPathParams = {
  assignmentId: string;
};

export type PatchV3GfwAssignmentsAssignmentIdStatusError = Fetcher.ErrorWrapper<undefined>;

export type PatchV3GfwAssignmentsAssignmentIdStatusRequestBody = {
  status: "open" | "completed" | "on hold";
};

export type PatchV3GfwAssignmentsAssignmentIdStatusVariables = {
  body: PatchV3GfwAssignmentsAssignmentIdStatusRequestBody;
  pathParams: PatchV3GfwAssignmentsAssignmentIdStatusPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Allow the user to update the status of the asssignment even if they are monitors but not creators of the assignment. Monitors can perform on hold to open and open to on hold status change. Creators can perform all status changes.
 */
export const fetchPatchV3GfwAssignmentsAssignmentIdStatus = (
  variables: PatchV3GfwAssignmentsAssignmentIdStatusVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.AssignmentResponse,
    PatchV3GfwAssignmentsAssignmentIdStatusError,
    PatchV3GfwAssignmentsAssignmentIdStatusRequestBody,
    {},
    {},
    PatchV3GfwAssignmentsAssignmentIdStatusPathParams
  >({ url: "/v3/gfw/assignments/{assignmentId}/status", method: "patch", ...variables, signal });

/**
 * Allow the user to update the status of the asssignment even if they are monitors but not creators of the assignment. Monitors can perform on hold to open and open to on hold status change. Creators can perform all status changes.
 */
export const usePatchV3GfwAssignmentsAssignmentIdStatus = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.AssignmentResponse,
      PatchV3GfwAssignmentsAssignmentIdStatusError,
      PatchV3GfwAssignmentsAssignmentIdStatusVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useCoreContext();
  return reactQuery.useMutation<
    Responses.AssignmentResponse,
    PatchV3GfwAssignmentsAssignmentIdStatusError,
    PatchV3GfwAssignmentsAssignmentIdStatusVariables
  >(
    (variables: PatchV3GfwAssignmentsAssignmentIdStatusVariables) =>
      fetchPatchV3GfwAssignmentsAssignmentIdStatus({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetV3GfwRoutesTeamTeamIdAreaAreaIdPathParams = {
  teamId: string;
  areaId: string;
};

export type GetV3GfwRoutesTeamTeamIdAreaAreaIdError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.Error;
}>;

export type GetV3GfwRoutesTeamTeamIdAreaAreaIdVariables = {
  pathParams: GetV3GfwRoutesTeamTeamIdAreaAreaIdPathParams;
} & CoreContext["fetcherOptions"];

/**
 * Returns all routes for team area either made for that team or made by the user
 */
export const fetchGetV3GfwRoutesTeamTeamIdAreaAreaId = (
  variables: GetV3GfwRoutesTeamTeamIdAreaAreaIdVariables,
  signal?: AbortSignal
) =>
  coreFetch<
    Responses.RoutesResponse,
    GetV3GfwRoutesTeamTeamIdAreaAreaIdError,
    undefined,
    {},
    {},
    GetV3GfwRoutesTeamTeamIdAreaAreaIdPathParams
  >({ url: "/v3/gfw/routes/team/{teamId}/area/{areaId}", method: "get", ...variables, signal });

/**
 * Returns all routes for team area either made for that team or made by the user
 */
export const useGetV3GfwRoutesTeamTeamIdAreaAreaId = <TData = Responses.RoutesResponse>(
  variables: GetV3GfwRoutesTeamTeamIdAreaAreaIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Responses.RoutesResponse, GetV3GfwRoutesTeamTeamIdAreaAreaIdError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useCoreContext(options);
  return reactQuery.useQuery<Responses.RoutesResponse, GetV3GfwRoutesTeamTeamIdAreaAreaIdError, TData>(
    queryKeyFn({
      path: "/v3/gfw/routes/team/{teamId}/area/{areaId}",
      operationId: "getV3GfwRoutesTeamTeamIdAreaAreaId",
      variables
    }),
    ({ signal }) => fetchGetV3GfwRoutesTeamTeamIdAreaAreaId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type QueryOperation =
  | {
      path: "/v3/gfw/assignments/user";
      operationId: "getV3GfwUser";
      variables: GetV3GfwUserVariables;
    }
  | {
      path: "/v3/gfw/assignments/open";
      operationId: "getV3GfwAssignmentsOpen";
      variables: GetV3GfwAssignmentsOpenVariables;
    }
  | {
      path: "/v3/gfw/assignments/areas/{areaId}";
      operationId: "getV3GfwAssignmentsAreaAreaId";
      variables: GetV3GfwAssignmentsAreaAreaIdVariables;
    }
  | {
      path: "/v3/gfw/assignments/{assignmentId}";
      operationId: "getV3GfwAssignmentsAssignmentId";
      variables: GetV3GfwAssignmentsAssignmentIdVariables;
    }
  | {
      path: "/v3/gfw/routes/user";
      operationId: "getV3GfwRoutesUser";
      variables: GetV3GfwRoutesUserVariables;
    }
  | {
      path: "/v3/gfw/routes/teams";
      operationId: "getV3GfwRoutesTeams";
      variables: GetV3GfwRoutesTeamsVariables;
    }
  | {
      path: "/v3/gfw/routes/{routeId}";
      operationId: "getV3GfwRoutesRouteId";
      variables: GetV3GfwRoutesRouteIdVariables;
    }
  | {
      path: "/v3/gfw/templates";
      operationId: "getV3GfwTemplates";
      variables: GetV3GfwTemplatesVariables;
    }
  | {
      path: "/v3/gfw/templates/{templateId}";
      operationId: "getV3GfwTemplatesTemplateId";
      variables: GetV3GfwTemplatesTemplateIdVariables;
    }
  | {
      path: "/v3/gfw/templates/{templateId}/answers";
      operationId: "getV3GfwTemplatesTemplateIdAnswers";
      variables: GetV3GfwTemplatesTemplateIdAnswersVariables;
    }
  | {
      path: "/v3/gfw/templates/{templateId}/answers/area/{areaId}";
      operationId: "getV3GfwTemplatesTemplateIdAnswersAreaAreaId";
      variables: GetV3GfwTemplatesTemplateIdAnswersAreaAreaIdVariables;
    }
  | {
      path: "/v3/gfw/templates/{templateId}/answers/{answerId}";
      operationId: "getV3GfwTemplatesTemplateIdAnswersAnswerId";
      variables: GetV3GfwTemplatesTemplateIdAnswersAnswerIdVariables;
    }
  | {
      path: "/v3/gfw/templates/allAnswers";
      operationId: "getV3GfwTemplatesAllAnswers";
      variables: GetV3GfwTemplatesAllAnswersVariables;
    }
  | {
      path: "/v3/gfw/areas/user";
      operationId: "getV3GfwAreasUser";
      variables: GetV3GfwAreasUserVariables;
    }
  | {
      path: "/v3/gfw/areas/userAndTeam";
      operationId: "getV3GfwAreasUserandteam";
      variables: GetV3GfwAreasUserandteamVariables;
    }
  | {
      path: "/v3/gfw/areas/{areaId}";
      operationId: "getV3GfwAreasAreaId";
      variables: GetV3GfwAreasAreaIdVariables;
    }
  | {
      path: "/v3/gfw/teams/myinvites";
      operationId: "getV3GfwTeamsMyinvites";
      variables: GetV3GfwTeamsMyinvitesVariables;
    }
  | {
      path: "/v3/gfw/teams/user/{userId}";
      operationId: "getV3GfwTeamsUserUserId";
      variables: GetV3GfwTeamsUserUserIdVariables;
    }
  | {
      path: "/v3/gfw/teams/{teamId}";
      operationId: "getV3GfwTeamsTeamId";
      variables: GetV3GfwTeamsTeamIdVariables;
    }
  | {
      path: "/v3/gfw/teams/{teamId}/users";
      operationId: "getV3GfwTeamsTeamIdUsers";
      variables: GetV3GfwTeamsTeamIdUsersVariables;
    }
  | {
      path: "/v3/gfw/assignments/allOpenUserForArea/{areaId}";
      operationId: "getV3GfwAssignmentsAllOpenUserForAreaAreaId";
      variables: GetV3GfwAssignmentsAllOpenUserForAreaAreaIdVariables;
    }
  | {
      path: "/v3/gfw/templates/versions/{editGroupId}/latest";
      operationId: "getV3GfwTemplatesVersionsIdLatest";
      variables: GetV3GfwTemplatesVersionsIdLatestVariables;
    }
  | {
      path: "/v3/gfw/templates/latest";
      operationId: "getV3GfwTemplatesLatest";
      variables: GetV3GfwTemplatesLatestVariables;
    }
  | {
      path: "/v3/gfw/templates/versions/{editGroupId}";
      operationId: "getV3GfwTemplatesVersionEditGroupId";
      variables: GetV3GfwTemplatesVersionEditGroupIdVariables;
    }
  | {
      path: "/v3/gfw/templates/public";
      operationId: "getV3GfwTemplatesPublic";
      variables: GetV3GfwTemplatesPublicVariables;
    }
  | {
      path: "/v3/gfw/arearelations/teams/areaTeams/{areaId}";
      operationId: "getV3GfwAreaTeamsAreaId";
      variables: GetV3GfwAreaTeamsAreaIdVariables;
    }
  | {
      path: "/v3/gfw/arearelations/teams/teamAreas/{teamId}";
      operationId: "getV3GfwArearelationsTeamsTeamAreasTeamId";
      variables: GetV3GfwArearelationsTeamsTeamAreasTeamIdVariables;
    }
  | {
      path: "/v3/gfw/arearelations/templates/areaTemplates/{areaId}";
      operationId: "getV3GfwArearelationsTemplatesAreaTemplatesAreaId";
      variables: GetV3GfwArearelationsTemplatesAreaTemplatesAreaIdVariables;
    }
  | {
      path: "/v3/gfw/arearelations/templates/templateAreas/{templateId}";
      operationId: "getV3GfwArearelationsTemplatesTemplateAreasTemplateId";
      variables: GetV3GfwArearelationsTemplatesTemplateAreasTemplateIdVariables;
    }
  | {
      path: "/v3/gfw/routes/team/{teamId}/area/{areaId}";
      operationId: "getV3GfwRoutesTeamTeamIdAreaAreaId";
      variables: GetV3GfwRoutesTeamTeamIdAreaAreaIdVariables;
    };
